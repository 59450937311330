import http from "./env";
import AuthHeader from "./env";
import * as serviceToken from"./serviceToken";

export const GetAll = () => {
    return http.get("/Planning/Get", {headers:{ Authorization: `Bearer ${serviceToken.gettokenstring()}` }});
  };
  export const Update = (planning) => {
    return http.post("/Planning/Update",planning, {headers:{ Authorization: `Bearer ${serviceToken.gettokenstring()}` }}    );
  };
  export const Insert = (planning) => {
    return http.post("/Planning/Insert",planning, {headers:{ Authorization: `Bearer ${serviceToken.gettokenstring()}` }}    );
  };
  export const Delete = (id) => {
    return http.delete("/Planning/Delete/"+id, {headers:{ Authorization: `Bearer ${serviceToken.gettokenstring()}` }}    );
  };
  export const GetByDeviceId=(id)=>
{
return http.get("/Planning/GetByDeviceId/"+id, {headers:{ Authorization: `Bearer ${serviceToken.gettokenstring()}` }});
}
