import React from "react";
import { Route, Redirect } from "react-router-dom";
import * as serviceToken from "../Services/serviceToken";

export const ProtectedAdminRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                if (serviceToken.isConnected()) {
                    let User = serviceToken.GetToken();
                    if (User != null && User.role == "admin") {
                        return <Component {...props} />;
                    } else {
                        return (
                            <Redirect
                                to={{
                                    pathname: "/user/device",
                                    state: {
                                        from: props.location,
                                    },
                                }}
                            />
                        );
                    }
                }else{
                    return (
                        <Redirect
                            to={{
                                pathname: "/user/device",
                                state: {
                                    from: props.location,
                                },
                            }}
                        />
                    );
                }

            }}
        />
    );
};

export const ProtectedUserRoute = ({
    component: Component,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={props => {
                if (serviceToken.isConnected()) {
                    let User = serviceToken.GetToken();
                    if (User != null && User.role == "user") {
                        return <Component {...props} />;
                    } else {
                        return (
                            <Redirect
                                to={{
                                    pathname: "/login",
                                    state: {
                                        from: props.location
                                    }
                                }}
                            />
                        );
                    }
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: {
                                    from: props.location
                                }
                            }}
                        />
                    );
                }

            }}
        />
    );
};