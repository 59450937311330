import http from "./env";
import AuthHeader from "./env";
import * as serviceToken from "./serviceToken";

export const GetAll = () => {
  return http.get("/Device/Get", { headers: { Authorization: `Bearer ${serviceToken.gettokenstring()}` } });
};
export const GetById = (id) => {
  return http.get("/Device/GetById/" + id, { headers: { Authorization: `Bearer ${serviceToken.gettokenstring()}` } });
};
export const Update = (device) => {
  return http.post("/Device/Update", device, { headers: { Authorization: `Bearer ${serviceToken.gettokenstring()}` } });
};
export const Insert = (device) => {
  return http.post("/Device/Insert", device, { headers: { Authorization: `Bearer ${serviceToken.gettokenstring()}` } });
};
export const Delete = (id) => {
  return http.delete("/Device/Delete/" + id, { headers: { Authorization: `Bearer ${serviceToken.gettokenstring()}` } });
};

export const GetGroupeByDeviceId = (id) => {
  return http.get("/Device/selectGroupsbydevice/" + id, { headers: { Authorization: `Bearer ${serviceToken.gettokenstring()}` } });
};
export const Addmember = (Listgroupes) => {
  return http.post("/Device/addGroupeList", Listgroupes, { headers: { Authorization: `Bearer ${serviceToken.gettokenstring()}` } });
};
export const GetDeviceUserbyid = (id) => {
  return http.get("/Device/GetByUserId/" + id, { headers: { Authorization: `Bearer ${serviceToken.gettokenstring()}` } });
};
export const Remove = (idd, idc) => {
  return http.get("/Device/removeGroupe/" + idd + "/" + idc, { headers: { Authorization: `Bearer ${serviceToken.gettokenstring()}` } });

};
export const checkResponse = (device) => {
  return http.post("/Device/checkResponse", device, { headers: { Authorization: `Bearer ${serviceToken.gettokenstring()}` } });
};

export const GetDeviceByClient = (id) => {
  return http.get("/Device/GetDeviceByClient/" + id, { headers: { Authorization: `Bearer ${serviceToken.gettokenstring()}` } });
};
export const InsertDevicePlanning = (plan) => {
  return http.post("/Device/InsertDevicePlanning/" , plan, { headers: { Authorization: `Bearer ${serviceToken.gettokenstring()}` } });
};
export const UpdateGoogleSheet = () => {
  return http.get("/Device/UpdateGoogleSheet", { headers: { Authorization: `Bearer ${serviceToken.gettokenstring()}` } });
};