import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import * as serviceToken from '../../Services/serviceToken';
import CustomButton from "components/CustomButtons/Button.js";

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(0.5)
    },
    label: {
        textTransform: 'none'
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    dialogWrapper: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5)
    },
    dialogTitle: {
        background: 'linear-gradient(60deg, #ab47bc, #8e24aa)',
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
}))
export default function ContactForm({ ...props }) {
    const emailRegex = RegExp(
        /.+@.+\.[A-Za-z]+$/
    );
    const phoneRegex = RegExp(
        /^[\+]?[0-9]{3,14}$/
    );
    const classes = useStyles();
    //déclaration des variables de state
    const { addOrEdit, setOpenPopup, recordForEdit, setRecordForEdit, openPopup } = props;
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [pageTitle, setpageTitle] = useState("Ajouter un Contact");
    const [nameeErr, setNameErr] = useState(false);
    const [emailErr, setEmailErr] = useState(false);
    const [numberErr, setNumberErr] = useState(false);

    //useEffect se déclenche au moment du chargement de composent
    //récupérer le sms urgent a modifier si il exite
    useEffect(() => {
       
  
        if (recordForEdit != null) {
            setName(recordForEdit.name);
            setEmail(recordForEdit.email);
            setNumber(recordForEdit.phoneNumber)
            setpageTitle("Modifier un Contact")
        }
        else{
            setName("");
            setEmail("");
            setNumber("")
            setpageTitle("Ajouter un Contact")
        }
    }, [recordForEdit])
const resetForm=()=>{
        setName("");
        setEmail("");
        setNumber("")
        setpageTitle("Ajouter un Contact")
}
    const onChangename = (e) => {
        setName(e.target.value);
        if (e.target.value.trim().length > 0) setNameErr(false)
    };

    const onChangeaddress = (e) => {
        setEmail(e.target.value);
        if (emailRegex.test(e.target.value.trim())) setEmailErr(false)
    };
    const onChangeNumber = (e) => {
        setNumber(e.target.value);
        if (phoneRegex.test(e.target.value.trim())) setNumberErr(false)
    };
    const handleSubmit = (e) => {
        let token =serviceToken.GetToken();
       
        e.preventDefault();
        const isValid = formValidation();
        if (isValid) {
            if (recordForEdit != null) {
                recordForEdit.name = name;
                recordForEdit.email = email;
                recordForEdit.phoneNumber = number;
                addOrEdit(recordForEdit)
            }
            else {

                let contact = {
                    name: name,
                    email: email,
                    phoneNumber : number,
                    userId:token.nameid,
                }
                addOrEdit(contact)
            }
            setName("");
            setEmail("");
            setNumber("");
        }
    };
    //form validation
    const formValidation = () => {
        let isValid = true;
        if (name.trim().length < 1) {
            isValid = false;
            setNameErr(true);
        }
        if (!emailRegex.test(email.trim())) {
            isValid = false;
            setEmailErr(true);
        }
        if (!phoneRegex.test(number.trim())) {
            isValid = false;
            setNumberErr(true);
        }
        return isValid
    }

    return (
        <Dialog open={openPopup}
            // classes={{ paper: classes.dialogWrapper }} 
            scroll={'paper'}
            fullWidth={true}
            maxWidth={'sm'}
        >
            <DialogTitle
                className={classes.dialogTitle}
            >
                <div style={{ display: 'flex', width: '100%', height: '100%' }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1, color: 'white' }}>
                        {pageTitle}
                    </Typography>
                    <Button
                        variant="contained" color="secondary"
                        onClick={() => { setOpenPopup(false);resetForm();setRecordForEdit(null); }}>
                        <CloseIcon />
                    </Button>
                </div>
            </DialogTitle>
            <form onSubmit={handleSubmit} className={classes.form}>
                <DialogContent dividers='true'>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="name"
                        label="Nom & Prénom"
                        name="name"
                        value={name}
                        onChange={onChangename}
                        error={nameeErr}
                        helperText={nameeErr ? "Le nom de contact doit être renseigné" : ""}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="address"
                        label="E-mail"
                        name="address"
                        value={email}
                        onChange={onChangeaddress}
                        error={emailErr}
                        helperText={emailErr ? "L'adresse email de contact doit être renseigné" : ""}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="number"
                        label="Numéro"
                        name="number"
                        value={number}
                        onChange={onChangeNumber}
                        error={numberErr}
                        helperText={numberErr ? "veuillez saisir un numéro de téléphone valide" : ""}
                    />
                </DialogContent>

                <DialogActions>
                    <Grid container>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={3}>
                            <CustomButton
                                fullWidth
                                variant="contained"
                                classes={{ root: classes.root, label: classes.label }}
                                onClick={() => {setOpenPopup(false);resetForm();setRecordForEdit(null); }}
                                color="primary"
                            >Annuler</CustomButton>

                        </Grid>

                        <Grid item xs={3}>
                            <CustomButton style={{ marginLeft: '10%' }}
                                fullWidth
                                type="submit"
                                variant="contained"
                                classes={{ root: classes.root, label: classes.label }}
                                color="primary">
                                Confirmer</CustomButton>
                        </Grid>
                    </Grid>
                </DialogActions>
            </form>
        </Dialog>
    );
};
