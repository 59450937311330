/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Contact from "views/Contact/Contact";
import Statistic from "views/Statistic/Statistic";
import Groupes from "views/Groupes/Groupes";
import Device from "views/Device/Device";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import GroupIcon from '@material-ui/icons/Group';
import BarChartIcon from '@material-ui/icons/BarChart';
const dashboardRoutes = [
  {
    path: "/device",
    name: "Dispositif",
    icon: NotificationsActiveIcon,
    component: Device,
    layout: "/admin",
  },
  {
    path: "/contact",
    name: "Contact",
    icon: Person,
    component: Contact,
    layout: "/admin",
  },
  {
    path: "/groupes",
    name: "Groupes",
    icon: GroupIcon,
    component: Groupes,
    layout: "/admin"
  },
  {
    path: "/stat",
    name: "Statistiques",
    icon: BarChartIcon,
    component: Statistic,
    layout: "/admin"
  },
];

export default dashboardRoutes;
