import http from "./env";
import * as serviceToken from"./serviceToken";

import AuthHeader from "./env";
export const GetAll = () => {
    return http.get("/Groupe/Get", {headers:{ Authorization: `Bearer ${serviceToken.gettokenstring()}` }}    );
  };
  export const GetById = (id) => {
    return http.get("/Groupe/GetById/"+id, {headers:{ Authorization: `Bearer ${serviceToken.gettokenstring()}` }}    );
  };
  export const Update = (groupe) => {
    return http.post("/Groupe/Update",groupe, {headers:{ Authorization: `Bearer ${serviceToken.gettokenstring()}` }}    );
  };
  export const Insert = (groupe) => {
    return http.post("/Groupe/Insert",groupe, {headers:{ Authorization: `Bearer ${serviceToken.gettokenstring()}` }}    );
  };
  export const Delete = (id) => {
    return http.delete("/Groupe/Delete/"+id, {headers:{ Authorization: `Bearer ${serviceToken.gettokenstring()}` }}    );
  };
  export const Getmembr = (id) => {
    return http.get("/Groupe/selectMemebers/"+id, {headers:{ Authorization: `Bearer ${serviceToken.gettokenstring()}` }}    );
  };
  export const Remove = (idg,idc) => {
    return http.get("/Groupe/removeMemeber/"+idg+"/"+idc, {headers:{ Authorization: `Bearer ${serviceToken.gettokenstring()}` }}    );
    
  };
  export const Addmember=(listcontact)=>{
    return http.post("/Groupe/addMemeberList",listcontact, {headers:{ Authorization: `Bearer ${serviceToken.gettokenstring()}` }}    );
  };
  export const GetGroupeUserbyid=(id)=>{
    return http.get("/Groupe/GetByUserId/"+id, {headers:{ Authorization: `Bearer ${serviceToken.gettokenstring()}` }}    );
    };

    export const getexepte=(currentList,userId)=>
{
  return http.post("/Groupe/GetExcept/"+userId,currentList, {headers:{ Authorization: `Bearer ${serviceToken.gettokenstring()}` }}  );
}; 