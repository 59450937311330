
import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { Button, TextField, Table, TableRow, TableCell, TableHead, TableBody, Tooltip, TablePagination, Dialog, DialogContent, DialogTitle, Typography, InputAdornment, DialogActions, Grid, FormGroup, FormControlLabel, Checkbox } from "@material-ui/core";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import * as serviceGroupe from '../../Services/serviceGroupe';
import * as serviceDevice from '../../Services/serviceDevice';
import * as serviceHistory from '../../Services/serviceHistory';
import CloseIcon from '@material-ui/icons/Close';
import { Search } from "@material-ui/icons";
import AddIcon from '@material-ui/icons/Add';
import AddGroupeDevice from "./AddGroupeDevice";
import CustomButton from "components/CustomButtons/Button.js";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { green } from "@material-ui/core/colors";
import Detailerreur from "./Detailerreur";
import { format } from 'date-fns';
import AnnouncementIcon from '@material-ui/icons/Announcement';
const styles = {
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    newButton: {
        position: "absolute",
        right: "10px",
        marginTop: "-35px",
        color: "#fff",
        borderColor: "white",
        textTransform: "none"
    },
    formControl: {
        margin: 5,
        minWidth: 120,
        maxWidth: 300,
    },
    dialogTitle: {
        background: 'linear-gradient(60deg, #ab47bc, #8e24aa)',
    },
};
const useStyles = makeStyles(styles);
export default function AddMembers({ ...props }) {
     //decllaration des variables de state
    const { deviceEdit, setdeviceEdit, openPopuphistory, setOpenPopuphistory } = props;
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);
    const [pageTitle, setpageTitle] = useState("Historique");
    const [ListHistory, setListHistrory] = useState([]);
    const classes = useStyles();
    const [search, setSearch] = useState("");
    const [MsgErreur, setMsgErreur] = useState("");
    const [openPopupErreur, setopenPopupErreur] = useState(false);
    useEffect(() => {
        Reload();

    }, [deviceEdit,openPopuphistory]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    //pagination
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, rowsPerPage));
        setPage(0);
    };

    const Reload = () => {
        if (deviceEdit != null && openPopuphistory) {
            serviceHistory.GetByDeviceId(deviceEdit._id).then(resp => {
                setListHistrory(resp.data);
            });

        }
    };




    const handleChange = (record, event) => {

    };
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 300,
                width: 250,
            },
        },
    };
    return (

        <Dialog open={openPopuphistory} scroll={'paper'}
            fullWidth={true}
            maxWidth={'md'}  >
            <DialogTitle className={classes.dialogTitle}>
                <div style={{ display: 'flex', width: '100%', height: '100%' }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1, color: 'white' }}>
                        {pageTitle}
                    </Typography>
                    <Button
                        variant="contained" color="secondary"
                        onClick={() => { setOpenPopuphistory(false) }}>
                        <CloseIcon />
                    </Button>
                </div>
            </DialogTitle>
            <DialogContent dividers='true'>
                <GridContainer>
                    <GridItem md={8}>

                    </GridItem>
                    <GridItem md={4}>


                    </GridItem>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Status</TableCell>
                                <TableCell align="center">Temps de réponse</TableCell>
                                <TableCell align="center">Date</TableCell>
                                <TableCell align="center">Erreur</TableCell>
                                <TableCell align="center">Détail Erreur</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ListHistory.filter(
                                (x) => x.date.includes(search))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((record, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="center">
                                            {record.status ? (
                                                <Tooltip title="active">
                                                    <CloudUploadIcon
                                                        style={{ color: green[500] }}
                                                    ></CloudUploadIcon>
                                                </Tooltip>
                                            ) : (
                                                    <Tooltip title="hors service">
                                                        <CloudDownloadIcon color="secondary"></CloudDownloadIcon>
                                                    </Tooltip>
                                                )}
                                        </TableCell>
                                        <TableCell align="center">{record.latency}</TableCell>
                                        <TableCell align="center">
                                            {format(Date.parse(record.date), 'kk:mm:ss -- dd/MM/yyyy')}
                                        </TableCell>
                                        <TableCell align="center">{record.responseHeader}</TableCell>

                                        <TableCell align="center">
                                            {
                                                record.status ? <div></div> :


                                                    <Tooltip title="Erreur">
                                                        <Button
                                                            color="primary"
                                                            onClick={() => {
                                                                setopenPopupErreur(true);
                                                                setMsgErreur(record.responseBody);
                                                            }}
                                                        >
                                                            <AnnouncementIcon color="secondary" />
                                                        </Button>
                                                    </Tooltip>
                                            }
                                        </TableCell>



                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[rowsPerPage]}
                        component="div"
                        labelRowsPerPage=''
                        count={ListHistory.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                    />
                </GridContainer>
            </DialogContent>

            <Detailerreur
                MsgErreur={MsgErreur}
                openPopupErreur={openPopupErreur}
                setopenPopupErreur={setopenPopupErreur}>
            </Detailerreur>
        </Dialog>
    );
}
