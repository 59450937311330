import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import SettingsIcon from "@material-ui/icons/Settings";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import clsx from "clsx";
import * as serviceToken from '../../Services/serviceToken';
import * as serviceDevicetype from '../../Services/serviceDevicetype';
import * as serviceConfig from '../../Services/serviceConfig';
import CustomButton from "components/CustomButtons/Button.js";
import Box from '@material-ui/core/Box';
import * as serviceDevice from "../../Services/serviceDevice";
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from "@material-ui/icons/Check";
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns';
import * as servicePlanning from "../../Services/servicePlanning";
import * as moment from 'moment';
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0.5),
  },
  label: {
    textTransform: "none",
  },
  form: {
    width: "100%", // Fix IE 11 issue.

    // marginTop: theme.spacing(1),
  },
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
    height: "auto",
    maxHeight: "80%",
  },
  dialogTitle: {
    background: 'linear-gradient(60deg, #ab47bc, #8e24aa)',
  },
  selectInput: {
    //margin: theme.spacing(1),
    minWidth: 120,
    width: "100%",
    marginTop: 8,
  },
  textInput: {
    marginTop: 0,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function DeviceForm({ ...props }) {
  const classes = useStyles();
  //déclaration des variables de state
  const {
    addOrEdit, //submit
    setOpenPopup, //close popup
    recordForEdit, //selected record for edit
    setRecordForEdit,
    openPopup, //varible affectd to the popup
  } = props;
  const [pageTitle, setpageTitle] = useState("Ajouter un device");

  const [name, setName] = useState("");
  const [codeAff, setCodeAff] = React.useState("");
  const [address, setaddress] = useState("");
  const [frequency, setFrequency] = React.useState("");
  const [delayTO, setDelayTO] = React.useState("");
  const [client, setClient] = React.useState("");
  const [apiMethod, setApiMethod] = React.useState("GET");
  const [apiToken, setApiToken] = React.useState("");
  const [apiBody, setApiBody] = React.useState("");
  const [startDate, setStartDate] = React.useState("00:00");
  const [stopDate, setStopDate] = React.useState("23:59");
  const [planning, setplanning] = React.useState({ startDate: '', stopDate: '' });

  const [nameErr, setNameErr] = useState(false);
  const [codeAffErr, setcodeAffErr] = useState(false);
  const [addresserr, setaddresserr] = useState(false);
  const [frequencyerr, setfrequencyerr] = useState(false);
  const [delayTOerr, setDelayTOerr] = useState(false);
  const [clienterr, setClientErr] = useState(false);
  const [apiTokenerr, setApiTokenerr] = useState(false);
  const [devicetypes, setdevicetypes] = useState([]);
  const [selectedType, setSelectedType] = useState();
  const [typeString, setTypeString] = useState("");
  const [urlState, seturlState] = useState(0);
  const [TimeErr, setTimeErr] = useState(false);
  
  //useEffect se déclenche au moment du chargement de composent
  //récupérer le sms urgent a modifier si il exite
  useEffect(() => {
    serviceDevicetype.GetAll().then(res => {
      setdevicetypes(res.data);
      setSelectedType(res.data[0]);
      setTypeString(res.data[0].type)
      if (recordForEdit != null) {
        setName(recordForEdit.name);
        setaddress(recordForEdit.address);
        setCodeAff(recordForEdit.codeAffaire);
        setFrequency(recordForEdit.frequency);
        setClient(recordForEdit.client);
        setApiToken(recordForEdit.token);
        setApiBody(recordForEdit.body);
        setDelayTO(recordForEdit.delayTimeOut);
        setApiMethod(recordForEdit.method);
        setpageTitle("Modifier un dispositif");
        setSelectedType(res.data.find(x => x._id === recordForEdit.deviceType));
        setTypeString(res.data.find(x => x._id === recordForEdit.deviceType).type)
        servicePlanning.GetByDeviceId(recordForEdit._id).then(res => {
          setplanning(res.data)
            setStartDate(moment(res.data.startDate).format("HH:mm"))
            setStopDate(moment(res.data.stopDate).format("HH:mm"))
          
        })
      } else {
        setName("");
        setaddress("");
        setCodeAff("");
        setFrequency("");
        setApiToken("");
        setApiBody("");
        setDelayTO("");
        setApiMethod("GET");
        setClient("");
        setStartDate("00:00")
        setStopDate("23:59")
        serviceConfig.GetAll().then(resp =>{
          setFrequency(resp.data.find(l=>l.key==="frequency").value);
        })
        setpageTitle("Ajouter un dispositif")
      }
    })
    
  }, [recordForEdit]);

  const resetForm = () => {
    setName("");
        setaddress("");
        setCodeAff("");
        setFrequency("");
        setApiToken("");
        setApiBody("");
        setDelayTO("");
        setApiMethod("GET");
        setClient("");
        setStartDate("00:00")
        setStopDate("23:59")
        serviceConfig.GetAll().then(resp =>{
          setFrequency(resp.data.find(l=>l.key==="frequency").value);
        })
        setpageTitle("Ajouter un dispositif")
  };
  const onChangeName = (e) => {
    setName(e.target.value);
    if (e.target.value.trim().length > 0) setNameErr(false);
  };
  const onChangeCodeAff = (e) => {
    setCodeAff(e.target.value);
    if (e.target.value.trim().length > 0) setcodeAffErr(false);
  };
  const onChangeAddress = (e) => {
    setaddress(e.target.value);
    if (e.target.value.trim().length > 0) setaddresserr(false);
    seturlState(0)
  };
  const onChangeType = (e) => {
    setSelectedType(e.target.value);
    setTypeString(e.target.value.type)
  };
  const onChangeFrequency = (e) => {
    setFrequency(e.target.value);
    if (e.target.value.length > 0) setfrequencyerr(false);
  };
  const onChangeDelayTO = (e) => {
    setDelayTO(e.target.value);
    if (e.target.value.length > 0) setDelayTOerr(false);
  };
  const onChangeClient = (e) => {
    setClient(e.target.value);
    if (e.target.value.length > 0) setClientErr(false);
  };
  const onChangeApiToken = (e) => {
    setApiToken(e.target.value);
    if (e.target.value.trim().length > 0) setApiTokenerr(false);
  };
  const onChangeApiMethod = (e) => {
    setApiMethod(e.target.value);
  };
  const onChangeApiBody = (e) => {
    setApiBody(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      let token = serviceToken.GetToken();
      if (recordForEdit != null) {
        recordForEdit.name = name;
        recordForEdit.address = address;
        recordForEdit.deviceType = selectedType._id;
        recordForEdit.frequency = parseInt(frequency);
        recordForEdit.codeAffaire = codeAff;
        recordForEdit.delayTimeOut = 0;
        recordForEdit.method = apiMethod;
        recordForEdit.body = apiBody;
        recordForEdit.token = apiToken;
        recordForEdit.client = client;
        let start= new Date();
        let stop= new Date();
        start.setHours(startDate.split(":")[0])
        start.setMinutes(startDate.split(":")[1])
        start.setSeconds(0)

        stop.setHours(stopDate.split(":")[0])
        stop.setMinutes(stopDate.split(":")[1])
        stop.setSeconds(0)

        planning.startDate = start;
        planning.stopDate = stop;
        addOrEdit(recordForEdit, planning);
      } else {
        let newDevice = {
          name: name,
          address: address,
          frequency: parseInt(frequency),
          status: true,
          track: true,
          codeAffaire: codeAff,
          deviceType: selectedType._id,
          delayTimeOut: 0,
          method: apiMethod,
          body: apiBody,
          token: apiToken,
          client: client,
          userId: token.nameid
        };
        let start= new Date();
        let stop= new Date();
        start.setHours(startDate.split(":")[0])
        start.setMinutes(startDate.split(":")[1])
        start.setSeconds(0)
        stop.setHours(stopDate.split(":")[0])
        stop.setMinutes(stopDate.split(":")[1])
        stop.setSeconds(0)
        let pln = {
          startDate: start,
          stopDate: stop
        }
        addOrEdit(newDevice, pln);
      }
    }

  };
  //form validation
  const formValidation = () => {
    let isValid = true;
    if (name.trim().length < 1) {
      isValid = false;
      setNameErr(true);
    }
    if (address.trim().length < 1) {
      isValid = false;
      setaddresserr(true);
    }
    if (codeAff.trim().length < 1) {
      isValid = false;
      setcodeAffErr(true);
    }
    if (frequency.length < 1) {
      isValid = false;
      setfrequencyerr(true);
    }
    if (client.length < 1) {
      isValid = false;
      setClientErr(true);
    }
    if(!compareTime(startDate,stopDate)){
      isValid = false;
      setTimeErr(true);
    }
    return isValid;
  };
  const testLink = () => {
    seturlState(1)
    let newDevice = {
      address: address,
      deviceType: selectedType._id,
      method: apiMethod,
      body: apiBody,
      token: apiToken,
    };
    serviceDevice.checkResponse(newDevice).then((resp) => {
      if (resp.data == true) seturlState(2)
      if (resp.data == false) seturlState(3)
    });
  }
  const handleChangestartDate = (newValue) => {
    if(compareTime(newValue.target.value,stopDate))setTimeErr(false)
    setStartDate(newValue.target.value);
  }
  const handleChangestopDate = (newValue) => {
    if(compareTime(startDate,newValue.target.value))setTimeErr(false)
    setStopDate(newValue.target.value);
  }
  const compareTime=(start,stop)=>{
    if(moment('01/01/2000 ' + start + 'Z')>=moment('01/01/2000 ' + stop + 'Z'))
    return(false)
    else
    return(true)
  }
  return (
    <>
      <Dialog
        open={openPopup}
        scroll={'paper'}
        maxWidth="md">
        <DialogTitle className={classes.dialogTitle}>
          <div style={{ display: "flex", width: '100%', height: '100%' }}>
            <Typography variant="h6" component="div" style={{ flexGrow: 1, color: 'white' }}>
              {pageTitle}
            </Typography>
            <Button
              variant="contained" color="secondary"
              onClick={() => {
                setRecordForEdit(null);
                resetForm();
                setOpenPopup(false);
              }}
            >
              <CloseIcon />
            </Button>
          </div>
        </DialogTitle>
        <form onSubmit={handleSubmit} className={classes.form}>
          <DialogContent dividers='true'>
            <Grid container>
              <Grid item xs={6} style={{ paddingRight: '2%' }} >
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="name"
                  label="Libellé"
                  name="name"
                  value={name}
                  onChange={onChangeName}
                  error={nameErr}
                  helperText={
                    nameErr ? "Le nom du dispositif doit être renseigné" : ""
                  }
                  className={classes.textInput}
                />
              </Grid>
              <Grid item xs={6} >
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="codeAff"
                  label="Code affaire"
                  name="codeAff"
                  value={codeAff}
                  onChange={onChangeCodeAff}
                  error={codeAffErr}
                  helperText={
                    codeAffErr
                      ? "Le code affaire du dispositif doit être renseigné"
                      : ""
                  }
                  className={classes.textInput}
                />
              </Grid>
              <Grid item xs={6} style={{ paddingRight: '2%' }}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="address"
                  label="Lien"
                  name="address"
                  value={address}
                  onChange={onChangeAddress}
                  error={addresserr}
                  helperText={
                    addresserr ? "Le lien du dispositif doit être renseigné" : ""
                  }
                  className={classes.textInput}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField type="number"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="frequence"
                  label="Fréquence"
                  name="frequence"
                  value={frequency}
                  onChange={onChangeFrequency}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">S</InputAdornment>,
                  }}
                  error={frequencyerr}
                  helperText={
                    addresserr ? "La fréquence du dispositif doit être renseigné" : ""
                  }
                  className={classes.textInput}
                />
              </Grid>
              <Grid item xs={6} style={{ paddingRight: '2%' }}>
                <FormControl variant="outlined" className={classes.selectInput}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Type
                </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={selectedType}
                    onChange={onChangeType}
                    label="Type">
                    {devicetypes.map(type => (
                      <MenuItem key={type._id} value={type}>{type.type}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  style={{ marginTop: '2%' }}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="client"
                  label="Client"
                  name="client"
                  value={client}
                  onChange={onChangeClient}
                  error={clienterr}
                  helperText={
                    clienterr ? "Le client doit être renseigné" : ""
                  }
                  className={classes.textInput}
                />
              </Grid>

              <Grid item xs={6} style={{ paddingRight: '2%' }}>
                <TextField
                  fullWidth
                  id="startDate"
                  label="Heure début"
                  type="time"
                  error={TimeErr}
                  onChange={handleChangestartDate}
                  value={startDate}
                  className={classes.textField}
                  helperText={
                    TimeErr ? "l'heure début doit être inférieur à l'heur fin" : ""
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 60, // 1 min
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="stopDate"
                  label="Heure fin"
                  type="time"
                  onChange={handleChangestopDate}
                  value={stopDate}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 60, // 1 min
                  }}
                />
              </Grid>
            </Grid>
            {typeString == "API" && (
              <div>
                <FormControl
                  variant="outlined"
                  className={classes.selectInput}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Méthode
                    </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={apiMethod}
                    onChange={onChangeApiMethod}
                    label="Méthode de l'api"
                  >
                    <MenuItem value={"GET"} >GET</MenuItem>
                    <MenuItem value={"POST"}>POST</MenuItem>
                    <MenuItem value={"PUT"}>PUT</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="apiToken"
                  label="Token de l'api"
                  name="apiToken"
                  value={apiToken}
                  onChange={onChangeApiToken}
                  error={apiTokenerr}
                  helperText={
                    addresserr
                      ? "Le token de l'api doit être renseigné"
                      : ""
                  }
                />
                {(apiMethod == "POST" ||
                  apiMethod == "PUT" ) && (
                    <div>

                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="apiBody"
                        label="Body de l'api"
                        name="apiBody"
                        value={apiBody}
                        onChange={onChangeApiBody}
                        multiline
                        rows={4}
                        defaultValue="{}"
                        className={classes.textInput}
                      />
                    </div>
                  )}
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Grid container>
              <Grid item xs={4}></Grid>
              <Grid item xs={2}>
                <CustomButton
                  fullWidth
                  variant="contained"
                  classes={{ root: classes.root, label: classes.label }}
                  onClick={() => { setRecordForEdit(null);resetForm(); setOpenPopup(false) }}
                  color="primary"
                >Annuler</CustomButton>

              </Grid>

              <Grid item xs={2}>
                <CustomButton style={{ marginLeft: '10%' }}
                  fullWidth
                  type="submit"
                  variant="contained"
                  classes={{ root: classes.root, label: classes.label }}
                  color="primary">
                  Confirmer</CustomButton>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                {urlState == 0 && <CustomButton
                  fullWidth
                  variant="contained"
                  classes={{ root: classes.root, label: classes.label }}
                  onClick={() => { testLink() }}>
                  Tester l'URL</CustomButton>}

                {urlState == 1 && <CircularProgress />}

                {urlState == 2 && <CustomButton
                  fullWidth
                  variant="contained"
                  classes={{ root: classes.root, label: classes.label }}
                  startIcon={<CheckIcon />}
                  onClick={() => { testLink() }}
                  color="success">
                  Retester l'URL</CustomButton>}

                {urlState == 3 && <CustomButton
                  fullWidth
                  variant="contained"
                  classes={{ root: classes.root, label: classes.label }}
                  startIcon={<CloseIcon />}
                  onClick={() => { testLink() }}
                  color="danger">
                  Retester l'URL</CustomButton>}
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
