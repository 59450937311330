
import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { Button, TextField, Table, TableRow, TableCell, TableHead, TableBody, Checkbox, TablePagination, Dialog, DialogContent, DialogTitle, Typography, InputAdornment, DialogActions, Grid, FormGroup, FormControlLabel } from "@material-ui/core";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import * as serviceGroupe from '../../Services/serviceGroupe';
import * as serviceDevice from '../../Services/serviceDevice';
import * as serviceToken from '../../Services/serviceToken';
import CloseIcon from '@material-ui/icons/Close';
import { Search } from "@material-ui/icons";
import AddIcon from '@material-ui/icons/Add';
import AddGroupeDevice from "./AddGroupeDevice";
import CustomButton from "components/CustomButtons/Button.js";

const styles = {
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    newButton: {
        position: "absolute",
        right: "10px",
        marginTop: "-35px",
        color: "#fff",
        borderColor: "white",
        textTransform: "none"
    },
    formControl: {
        margin: 5,
        minWidth: 120,
        maxWidth: 300,
    },
    dialogTitle: {
        background: 'linear-gradient(60deg, #ab47bc, #8e24aa)',
    },
};
const useStyles = makeStyles(styles);
export default function AddMembers({ ...props }) {
     //decllaration des variables de state
    const { openPopupAddGroupeDevice, setopenPopupAddGroupeDevice ,Listgroupe,confirmeSelect, setListNewgroupe} = props;
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);
    const [pageTitle, setpageTitle] = useState("Sélectionner des groupes");
    const [groups, setGroupe] = useState([]);
    const classes = useStyles();
    const [search, setSearch] = useState("");
    const [openAddGroupeDevice, setOpenAddGroupeDevice] = useState(false);
    const [SelectedGroups, setSelectedGroups] = useState([]);

    useEffect(() => {
        let token = serviceToken.GetToken();
        serviceGroupe.getexepte(Listgroupe, token.nameid).then(res => {
            setGroupe(res.data)
        })
    }, [Listgroupe]);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    //pagination
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, rowsPerPage));
        setPage(0);
    };
    const onRemovee = (iddv, idc) => {
        serviceDevice.Remove(iddv, idc).then((res) => {
            Reload();
        })
    };
    const Reload = () => {
        // if(deviceForEdit!=null){
        //     serviceDevice.GetDeviceUserbyid(deviceForEdit._id).then(resp => {
        //         setGroupe(resp.data);

        //     });
        // }
    }

    const handleChange = (record, event) => {

        if (SelectedGroups.indexOf(record) === -1) {
            SelectedGroups.push(record);
            
        }
        else {
            SelectedGroups.splice(SelectedGroups.indexOf(record), 1)
        }
        setListNewgroupe(SelectedGroups);
    };
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 300,
                width: 250,
            },
        },
    };
    return (
        <Dialog open={openPopupAddGroupeDevice} scroll={'paper'}
            fullWidth={true}
            maxWidth={'md'}  >
            <DialogTitle className={classes.dialogTitle}>
                <div style={{ display: 'flex', width: '100%', height: '100%' }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1, color: 'white' }}>
                        {pageTitle}
                    </Typography>
                    <Button
                        variant="contained" color="secondary"
                        onClick={() => { setopenPopupAddGroupeDevice(false);setSelectedGroups([]); }}>
                        <CloseIcon />
                    </Button>
                </div>
            </DialogTitle>
            <DialogContent dividers='true'>
                <GridContainer>
                    <GridItem md={8}>
                    </GridItem>
                    <GridItem md={4}>
                        <TextField
                            fullWidth
                            margin="normal"
                            id="search"
                            name="search"
                            value={search}
                            label="Recherche..."
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(e) => {
                                setSearch(e.target.value);
                            }}
                        />
                    </GridItem>
                </GridContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center">Nom</TableCell>
                            <TableCell align="center">Nombre De Contacts</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {groups.filter(
                            (x) => x.name.includes(search))
                            .map((record, index) => (

                                <TableRow key={index}>
                                    <TableCell align="center">
                                        <Checkbox onChange={(e) => handleChange(record, e)} color="primary" />
                                    </TableCell>
                                    <TableCell align="center">{record.name}</TableCell>
                                    <TableCell align="center">{record.nbrMember}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Grid container>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={2}>
                        <CustomButton
                            fullWidth
                            variant="contained"
                            classes={{ root: classes.root, label: classes.label }}
                            onClick={() => { setopenPopupAddGroupeDevice(false);setSelectedGroups([]); }}>
                            Annuler</CustomButton>
                    </Grid>
                    <Grid item xs={2}>
                        <CustomButton style={{ marginLeft: '10%' }}
                            fullWidth
                            onClick={() => { confirmeSelect(SelectedGroups);setSelectedGroups([]); }}
                            variant="contained"
                            classes={{ root: classes.root, label: classes.label }}
                            color="primary">
                            Confirmer</CustomButton>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
