import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Redirect, useHistory } from "react-router-dom";
import { useToasts } from 'react-toast-notifications';
import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import * as serviceUser from "../../Services/serviceUser";
//expression réguliere d'un email
const emailRegex = RegExp(
  /.+@.+\.[A-Za-z]+$/
);
const passwordRegex = RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#:;<>,.%*?&])[A-Za-z\d@$!#:;<>,.%*?&]{8,}$/
);
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    marginTop: "3%",
    margin: theme.spacing(0),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Signup = ({ ...props }) => {
  //déclaration des variables de state
  const classes = useStyles();
  const { addToast } = useToasts();
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [CPass, setCPass] = useState("");
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [FNE, setFNE] = useState(false);
  const [LNE, setLNE] = useState(false);
  const [EME, setEME] = useState(false);
  const [MPE, setMPE] = useState(false);
  const [CMPE, setCMPE] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [validCaptch, setvalidCaptch] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [showPasswordC, setShowPasswordC] = useState(false);
  const handleClickShowPasswordC = () => setShowPasswordC(!showPasswordC);
  const handleMouseDownPasswordC = () => setShowPasswordC(!showPasswordC);
  const user = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    role: "user",
  }
  const phoneRegex = RegExp(
    /^[\+]?[0-9]{3,14}$/
);
  //verifier la validité des inputs
  //verifier que l'email saisie est unique
  //appler l'api register
  const callback = (v) => {
    setvalidCaptch(true);
  }
  const handleSubmit = e => {
    e.preventDefault();
    const isValid = formValidation();
    if (isValid) {//&& validCaptch
      user.firstName = first;
      user.lastName = last;
      user.email = email;
      user.password = pass;
      serviceUser.Register(user).then(response=>{
        if (response.data == "error") { addToast("Cette adresse e-mail est déjà utilisée", { appearance: 'error' }) }
        else {
          history.push("/login")
          addToast("votre inscription a bien été enregistrée", { appearance: 'info' })
        }
      })
    }
  };
  //form validation
  const formValidation = () => {
    let isValid = true;
    if (first.trim().length < 1) {
      isValid = false;
      setFNE(true);
    }
    if (last.trim().length < 1) {
      isValid = false;
      setLNE(true);
    }
    if (!emailRegex.test(email.trim())) {
      isValid = false;
      setEME(true);
    }
    if (!passwordRegex.test(pass.trim())) {
      isValid = false;
      setMPE(true);
    }
    if (pass != CPass) {
      isValid = false;
      setCMPE(true);
    }
    // if (fonction.trim().length < 1) {
    //   isValid = false
    //   setFE(true);
    // }
    return isValid
  }
  //binding des champs input avec les variables de state
  const onChangefirst = (e) => { setFirst(e.target.value); if (e.target.value.trim().length > 0) setFNE(false) }
  const onChangelast = (e) => { setLast(e.target.value); if (e.target.value.trim().length > 0) setLNE(false) }
  const onChangeEmail = (e) => { setEmail(e.target.value); if (emailRegex.test(e.target.value.trim())) setEME(false) }
  const onChangepass = (e) => {
    setPass(e.target.value);
    if (passwordRegex.test(e.target.value.trim())) setMPE(false)
    if (e.target.value == CPass) setCMPE(false)
  }
  const onChangeCpass = (e) => {
    setCPass(e.target.value)
    if (e.target.value == pass) setCMPE(false)
  }
  const history = useHistory();
  const back = () => { history.push("/login") }
  return (
    <Container style={{height:'880px'}} component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <PersonAddIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Inscription
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit} noValidate autocomplete="off">

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="last"
            label="Nom"
            name="last"
            autoComplete="off"
            value={last}
            onChange={onChangelast}
            error={LNE}
            helperText={LNE ? "Veuillez saisir un nom correct" : ""}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="first"
            label="Prenom"
            name="first"
            autoComplete="off"
            value={first}
            onChange={onChangefirst}
            error={FNE}
            helperText={FNE ? "Veuillez saisir un prénom correct" : ""}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Adresse e-mail"
            name="email"
            autoComplete="off"

            value={email}
            onChange={onChangeEmail}
            error={EME}
            helperText={EME ? "Veuillez saisir un email correct" : ""}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type={showPassword ? "text" : "password"}
            InputProps={{ // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
              
              
            }}
            name="password"
            label="Mot de passe"
            id="password"
            autoComplete="new-password"
            value={pass}
            onChange={onChangepass}
            error={MPE}
            helperText={MPE ? "Le mot de passe doit comporter au moins 8 caractères, une majuscule, une minuscule, un caractère spécial et un caractère numérique" : ""}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="cpassword"
            label="Confirmer mot de passe"
            type={showPasswordC ? "text" : "password"}
            InputProps={{ // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPasswordC}
                    onMouseDown={handleMouseDownPasswordC}
                  >
                    {showPasswordC ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            id="cpassword"
            autoComplete="off"
            value={CPass}
            onChange={onChangeCpass}
            error={CMPE}
            helperText={CMPE ? "Les mots de passe saisis ne correspondent pas" : ""}

          />
          {/* <ReCAPTCHA
            sitekey="6Lcdql4aAAAAAD8811ePvUbueDieYtAll0nPqfPn"
            onChange={callback} /> */}
          <Button
            type="button"
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Confirmer
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2" onClick={back}>
                Se Connecter
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
      </Box>
    </Container>

  );
}

export default Signup;
