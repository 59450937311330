import { InputAdornment, Paper, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useState, useEffect } from "react";
import * as serviceStatistic from '../../Services/serviceStatistic';
import { Line } from 'react-chartjs-2';
import { Bar, HorizontalBar } from 'react-chartjs-2';
import * as serviceToken from '../../Services/serviceToken';
export default function AverageTimeStat({ ...props }) {
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [search, setSearch] = useState("");
  const [stats, setStats] = useState([]);

  useEffect(() => {
    Reload();
  }, []);
  const Reload = () => {
    let token = serviceToken.GetToken();
    serviceStatistic.GetDeviceAverageLatency(token.nameid).then(resp => {
      setStats(resp.data);
    });
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  //pagination
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, rowsPerPage));
    setPage(0);
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={8}>
        <Paper style={{height:"700px"}}>
          <HorizontalBar
            data={{
              labels:stats.map(x => x.deviceName),
              datasets: [{
                label: "temps de réponse moyen en millisecondes",
                data:stats.map(x => x.latency),
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1
              }],
            }}

            options={{
              maintainAspectRatio: false,
            }}
          />
          {/* <Bar
            data={{
              labels: stats.map(x => x.name),
              datasets: [{
                label: "temps de réponse moyen en millisecondes",
                data: stats.map(x => x.delayTimeOut),
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1
              }],
            }}

            options={{
              maintainAspectRatio: true,
            }}
          /> */}
        </Paper>
      </GridItem>
      <GridItem xs={12} sm={12} md={4}>
        <TextField
          fullWidth
          margin="normal"
          id="search"
          name="search"
          value={search}
          label="Recherche..."
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Nom</TableCell>
              <TableCell align="center">Temps de réponse moyen</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stats.filter((x) => x.deviceName.toLowerCase().includes(search.toLowerCase()))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((record, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{record.deviceName}</TableCell>
                  <TableCell align="center">{record.latency}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[rowsPerPage]}
          component="div"
          labelRowsPerPage=''
          count={stats.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelDisplayedRows={({ from, to, count }) => `Page:${page+1}  (${from}-${to} de ${count})`}
        />
      </GridItem>
    </GridContainer>

  );
}
