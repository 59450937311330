import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Button, TextField, Table, InputAdornment, TableRow, TableCell, TableHead, TableBody, ButtonGroup, Tooltip, TablePagination } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import Search from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useToasts } from "react-toast-notifications";
import * as serviceContact from '../../Services/serviceContact';
import * as serviceToken from '../../Services/serviceToken';
import ContactForm from "./ContactForm";
import ConfirmDialog from "components/ConfirmDialog";
const styles = {
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  newButton: {
    position: "absolute",
    right: "10px",
    marginTop: "-35px",
    color: "#fff",
    borderColor: "white",
    textTransform: "none"
  },
  searchBar: {

  },
 
};

const useStyles = makeStyles(styles);

export default function Contact({ ...props }) {
  //decllaration des variables de state
  const [contacts, setContacts] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [search, setSearch] = useState("");
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const classes = useStyles();
  useEffect(() => {
      Reload();
  }, []);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  //pagination
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, rowsPerPage));
    setPage(0);
  };
  const { addToast } = useToasts();
  //faire appel a l'API ajouter ou modifier message et afficher et afficher une notification
  const addOrEdit = (record) => {
    if (record._id == null) {
      serviceContact.Insert(record).then((resp) => {
        addToast("Contact ajouté avec succès", { appearance: "info" });
        Reload();
      })
    } else
      serviceContact.Update(record).then((resp) => {
        addToast("Contact modifié avec succès", { appearance: "info" });
        Reload();
      })
    setRecordForEdit(null);
    setOpenPopup(false);
  };
  const openInPopup = (record) => {
    setRecordForEdit(record);
    setOpenPopup(true);
  };
  //supprimer un contact et afficher un notification
  const onDelete = (id) => {
    setConfirmDialog({ ...confirmDialog, isOpen: false })
    serviceContact.Delete(id).then((res) => {
      addToast("Contact supprimé", { appearance: "info" });
      Reload();
    })
  };
  const Reload = () => {
    let token = serviceToken.GetToken();
      serviceContact.GetContactUserbyid(token.nameid).then(resp => {
        setContacts(resp.data);
      });
  }

 
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Liste des contacts</h4>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              className={classes.newButton}
              onClick={() => {
                setOpenPopup(true);
                setRecordForEdit(null);
              }}
            >
              Nouveau contact
              </Button>

          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem md={9}> </GridItem>
              <GridItem md={3}>
                <TextField
                fullWidth
                  margin="normal"
                  id="search"
                  name="search"
                  value={search}
                  label="Recherche..."
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </GridItem>
            </GridContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Nom & Prénom</TableCell>
                  <TableCell align="center">Numéro de téléphone</TableCell>
                  <TableCell align="center">E-mail</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contacts.filter(
                  (x) => x.name.toLowerCase().includes(search.toLowerCase()) || x.email.toLowerCase().includes(search.toLowerCase()) || x.phoneNumber.toLowerCase().includes(search.toLowerCase())
                )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((record, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{record.name}</TableCell>
                      <TableCell align="center">{record.phoneNumber}</TableCell>
                      <TableCell align="center">{record.email}</TableCell>

                      <TableCell align="center">
                        <ButtonGroup variant="text">
                          <Tooltip title="Modifier">
                            <Button
                              color="primary"
                              onClick={() => {
                                openInPopup(record)
                              }}
                            >
                              <EditIcon color="primary" />
                            </Button></Tooltip>
                          <Tooltip title="Supprimer">
                            <Button
                              onClick={
                                () => {
                                  setConfirmDialog({
                                    isOpen: true,
                                    title: "Confirmer la suppression",
                                    subTitle: "Voulez-vous vraiment supprimer ce contact ?",
                                    
                                    onConfirm: () => { onDelete(record._id) }
                                  });
                                }
                              }
                            >
                              <DeleteIcon color="secondary" />
                            </Button></Tooltip>
                        </ButtonGroup></TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              component="div"
              labelRowsPerPage=''
              count={contacts.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelDisplayedRows={({ from, to, count }) => `Page:${page+1}  (${from}-${to} de ${count})`}
            />
          </CardBody>
        </Card>
      </GridItem>
      <ContactForm

        recordForEdit={recordForEdit}
        setRecordForEdit={setRecordForEdit}
        addOrEdit={addOrEdit}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      ></ContactForm>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </GridContainer>
  );
}
