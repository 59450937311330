import http from "./env";
import AuthHeader from "./env";
import * as serviceToken from"./serviceToken";

export const GetAll = () => {
    return http.get("/DeviceType/Get", {headers:{ Authorization: `Bearer ${serviceToken.gettokenstring()}` }} );
  };
  export const Update = (groupe) => {
    return http.post("/DeviceType/Update",groupe, {headers:{ Authorization: `Bearer ${serviceToken.gettokenstring()}` }}    );
  };
  export const Insert = (groupe) => {
    return http.post("/DeviceType/Insert",groupe, {headers:{ Authorization: `Bearer ${serviceToken.gettokenstring()}` }}    );
  };
  export const Delete = (id) => {
    return http.delete("/DeviceType/Delete/"+id, {headers:{ Authorization: `Bearer ${serviceToken.gettokenstring()}` }}    );
  };
  export const GetContactUserbyid=(id)=>
{
return http.get("/DeviceType/GetByUserId/"+id, {headers:{ Authorization: `Bearer ${serviceToken.gettokenstring()}` }});
}