import jwt_decode from "jwt-decode";
export const GetToken = () => {

    let token = localStorage.getItem("SAToken");
    let decode;
    if (token) decode = jwt_decode(token);
    return (decode)
  };
export const SetToken = (token) => {
    localStorage.setItem('SAToken', token)
  };
export const DeleteToken = () => {
    localStorage.removeItem('SAToken')
  };
  export const isConnected=()=>{
    let token = localStorage.getItem("SAToken");
    if(token!=null)return true
    return false
  }
  export const gettokenstring = () => {
    let token = localStorage.getItem("SAToken");
    return (token)
  };
