import axios from "axios";
import * as serviceToken from './serviceToken';
export default axios.create({
"baseURL": "https://sanalert.net/api",
    headers: {
        "Content-type": "application/json",
    },
});
export const AuthHeader = () => {
    let token = serviceToken.gettokenstring();
    let config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
    return (config);
}