import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, makeStyles, IconButton, Button } from '@material-ui/core'
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import CloseIcon from '@material-ui/icons/Close';


const useStyles = makeStyles(theme => ({
    dialog: {
        padding: theme.spacing(2),
        position: 'absolute',
        //top: theme.spacing(5)
        top: '40%',
    },
    dialogTitle: {
        textAlign: 'center',
        color:"white",
        backgroundColor:"#ab47bc",
        padding:'0px'
    },
    dialogContent: {
        textAlign: 'center',
        
    },
    dialogAction: {
        justifyContent: 'center'
    },
    titleIcon: {
        color: theme.palette.secondary.main,
        '&:hover': {
            cursor: 'default'
        },
        '& .MuiSvgIcon-root': {
            fontSize: '2rem',
        }
    },
    root: {
        margin: theme.spacing(0.5)
    },
    label: {
        textTransform: 'none'
        
    }
}))

export default function ConfirmDialog(props) {

    const { confirmDialog, setConfirmDialog } = props;
    const classes = useStyles()

    return (
        <Dialog open={confirmDialog.isOpen} classes={{ paper: classes.dialog }}>
            <DialogTitle 
            className={classes.dialogTitle}
            >
                Confirmation 
         </DialogTitle>

            <DialogContent className={classes.dialogContent}>
                <div variant="subtitle2">
                    {confirmDialog.subTitle}
                </div>
            </DialogContent>

            <DialogActions className={classes.dialogAction}>

                <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={confirmDialog.onConfirm}
                    classes={{ root: classes.root, label: classes.label }}
                >Oui</Button>
                <Button
                    type="button"
                    autoFocus
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    classes={{ root: classes.root, label: classes.label }}
                    onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
                >Non</Button>
            </DialogActions>
        </Dialog>
    )
}
