import http from "./env";
import AuthHeader from "./env";
import * as serviceToken from"./serviceToken";
export const GetAll = () => {
    return http.get("/TestCase/Get", {headers:{ Authorization: `Bearer ${serviceToken.gettokenstring()}` }}
    );
  };
  export const Update = (contact) => {
    return http.post("/TestCase/Update",contact, {headers:{ Authorization: `Bearer ${serviceToken.gettokenstring()}` }})    ;
  };
  export const Insert = (contact) => {
    return http.post("/TestCase/Insert",contact, {headers:{ Authorization: `Bearer ${serviceToken.gettokenstring()}` }}    );
  };
  export const Delete = (id) => {
    return http.delete("/TestCase/Delete/"+id, {headers:{ Authorization: `Bearer ${serviceToken.gettokenstring()}` }}    );
  };

export const GetByDeviceId=(id)=>
{
return http.get("/TestCase/GetByDeviceId/"+id,{headers:{ Authorization: `Bearer ${serviceToken.gettokenstring()}` }});
}