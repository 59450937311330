import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogTitle, Grid, makeStyles, Typography } from "@material-ui/core";
import { useToasts } from "react-toast-notifications";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    dialogWrapper: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5)
    },
    dialogTitle: {
        paddingRight: '0px'
    }
}))
const ResetPass = ({...props }) => {
    const {openPopup, title, setOpenPopup}= props;
    const classes = useStyles();
    const emailRegex = RegExp(
        /.+@.+\.[A-Za-z]+$/
    );
    const [EME, setEME] = useState(false);
    const [email, setEmail] = useState("");
    const { addToast } = useToasts();
    //envoyer le nouveau mot de passe vers l'email saisie si valide
    const handleSubmit = e => {
        e.preventDefault();
        const isValid = formValidation();
        if (isValid) {
            props.sendemail(email)
        }
        else { }
    };
    const formValidation = () => {
        let isValid = true;
        if (!emailRegex.test(email.trim())) {
            isValid = false;
            setEME(true);
        }
        return isValid
    }
    const onChangeEmail = (e) => { setEmail(e.target.value); if (emailRegex.test(e.target.value.trim())) setEME(false) }


    return (
        <Dialog open={openPopup} maxWidth="lg" classes={{ paper: classes.dialogWrapper }}>
            <DialogTitle className={classes.dialogTitle}>
                <div style={{ display: 'flex' }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                    <Button
                       
                        onClick={()=>{setOpenPopup(false)}}>
                        <CloseIcon />
                    </Button>
                </div>
            </DialogTitle>
            <DialogContent>
            <form onSubmit={handleSubmit}>
            <Grid container>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Adresse e-mail"
                    name="email"
                    value={email}
                    onChange={onChangeEmail}
                    error={EME}
                    helperText={EME ? "Veuillez saisir un email correct" : ""}
                />
                <Button variant="contained" color="primary" type="submit" style={{ marginLeft: '400px' }}>Envoyer</Button>
            </Grid>
        </form>
            </DialogContent>
        </Dialog>
        
    );
}

export default ResetPass;