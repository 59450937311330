import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import GridItem from "components/Grid/GridItem";
import * as serviceGroupe from '../../Services/serviceGroupe';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import moment from 'moment'
import AddMembers from "./AddMembers";
import * as serviceToken from '../../Services/serviceToken';
import CustomButton from "components/CustomButtons/Button.js";

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(0.5)
    },
    label: {
        textTransform: 'none'
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    dialogWrapper: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5)
    },
    dialogTitle: {
        background: 'linear-gradient(60deg, #ab47bc, #8e24aa)',
    },
}))



export default  function GroupesForm  ({ ...props }) {
    
    const classes = useStyles();
    //déclaration des variables de state
    const { addOrEdit, setOpenPopup, recordForEdit, setRecordForEdit,openPopup,Listmember,setListmember,ReloadGroups} = props;
    const [name, setName] = useState("");
    const [pageTitle, setpageTitle] = useState("Ajouter un groupe");
    const [nameeErr, setNameErr] = useState(false);
    
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);
    const [openPopupMembers, setOpenPopupMembers] = useState(false);
    const [selectedContacts,setSelectedContacts]=useState([])
    //useEffect se déclenche au moment du chargement de composent
    //récupérer le sms urgent a modifier si il exite
    useEffect(() => {
      
if(openPopup==true){
    let token =serviceToken.GetToken();
        if (recordForEdit != null) {
            setName(recordForEdit.name);
           
            setpageTitle("Modifier un groupe")
            Reload();
        }
        else{
            setName("");

            setpageTitle("Ajouter un Groupe")
            let groupeObj={date_deleted:moment(),userId:token.nameid}
          
            serviceGroupe.Insert(groupeObj).then((resp)=>{
 
 setRecordForEdit(resp.data)

            })
        }
    }
    },[openPopup])
    
    const onChangename = (e) => {
        setName(e.target.value);
        if (e.target.value.trim().length > 0) setNameErr(false)
    };
 
    const onRemovee = (idgrp,idc) => {
        
        serviceGroupe.Remove(idgrp,idc).then((res) => {
     
         
          Reload();
        })
      };
      const Reload=()=>{
        
        serviceGroupe.Getmembr(recordForEdit._id).then(resp=>{
        
        setListmember(resp.data)
       

      });
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };


      const confirmeSelect=(Listcontact)=>{
      
            let obj={contacts:Listcontact,
                id_groupe:recordForEdit._id}
            serviceGroupe.Addmember(obj).then(rep=>{
                setOpenPopupMembers(false);
                Reload();
        });


       
    }
    //pagination
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, rowsPerPage));
        setPage(0);
      };
    const handleSubmit = (e) => {
        e.preventDefault();
        const isValid = formValidation();
        if (isValid) {
            if (recordForEdit != null) {
                recordForEdit.name = name;
                recordForEdit.contacts=Listmember.map(c=> c._id)
                recordForEdit.nbrMember=Listmember.length
                addOrEdit(recordForEdit)
            }
            else {
                let contact = {
                    name: name,
                    
                }
                addOrEdit(contact)
            }
            setName("");
            
        }
    };
    //form validation
    const formValidation = () => {
        let isValid = true;
        if (name.trim().length < 1) {
            isValid = false;
            setNameErr(true);
        }
       
        return isValid
    }

    return (
        <Dialog open={openPopup}  scroll={'paper'}
        fullWidth={true}
        maxWidth={'md'}>
            <DialogTitle className={classes.dialogTitle}>
                <div style={{ display: 'flex', width: '100%', height: '100%' }}>
                    <Typography variant="h6" component="div"  style={{ flexGrow: 1, color: 'white' }}>
                        {pageTitle}
                    </Typography>
                    <Button
                       variant="contained" color="secondary"
                        onClick={()=>{ReloadGroups();setOpenPopup(false)}}>
                        <CloseIcon />
                    </Button>
                </div>
            </DialogTitle>
            <form onSubmit={handleSubmit} className={classes.form}>
            <DialogContent dividers='true'>
            <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="name"
                label="Libellé"
                name="name"
                value={name}
                onChange={onChangename}
                error={nameeErr}
                helperText={nameeErr ? "Le libellé de groupe doit être renseigné" : ""}
            />
           <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  color="primary"
                  style={{float: 'right',  textTransform: "none" }}
                  onClick={() => {
                    setOpenPopupMembers(true);
                    // setRecordForEdit(null);
                  }}
                >
                  Ajouter des membres
                </Button>
                <GridItem xs={12} sm={12} md={12}>
          
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Libellé</TableCell>
                    <TableCell align="center">Numéro de téléphone</TableCell>
                    <TableCell align="center">E-mail</TableCell>
            
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {Listmember.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((record, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">{record.name}</TableCell>
                        <TableCell align="center">{record.phoneNumber}</TableCell>
                        <TableCell align="center">{record.email}</TableCell>

                        <TableCell align="center">

                        <Tooltip title="Retirer  membre">
                            <Button
                              onClick={
                                () => {

                                    onRemovee(recordForEdit._id,record._id)
                                  
                                }
                              }
                            >
                              <HighlightOffIcon color="secondary" />
                            </Button></Tooltip>
                         </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                component="div"
                labelRowsPerPage=''
                count={Listmember.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
              />
        
        </GridItem>
        </DialogContent>
        <DialogActions>
        <Grid container>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={2}>
                            <CustomButton
                                fullWidth
                                variant="contained"
                                classes={{ root: classes.root, label: classes.label }}
                                onClick={() => { ReloadGroups();setRecordForEdit(null); setOpenPopup(false) }}
                                color="primary"
                                >Annuler</CustomButton>

                        </Grid>

                        <Grid item xs={2}>
                            <CustomButton style={{ marginLeft: '10%' }}
                                fullWidth
                                type="submit"
                                variant="contained"
                                classes={{ root: classes.root, label: classes.label }}
                                color="primary">
                                Confirmer</CustomButton>
                        </Grid>
                    </Grid>
            
                    </DialogActions>
        </form>
          
            <AddMembers
                setSelectedContacts={setSelectedContacts}
                confirmeSelect={confirmeSelect}
                Listmember={Listmember}
       
        openPopupMembers={openPopupMembers }
        setOpenPopupMembers={setOpenPopupMembers}
      
        ></AddMembers> 
        </Dialog>
        
    );
};
