
import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import AddIcon from '@material-ui/icons/Add';
import { Button, TextField, Table, TableRow, TableCell, TableHead, TableBody, Tooltip, TablePagination, Dialog, DialogContent, DialogTitle, Typography, InputLabel, Select, Input, MenuItem, Checkbox, ListItemText, FormControl, InputAdornment, DialogActions, Grid } from "@material-ui/core";

import * as serviceGroupe from '../../Services/serviceGroupe';
import * as serviceContact from '../../Services/serviceContact';
import * as serviceToken from '../../Services/serviceToken';
import CustomButton from "components/CustomButtons/Button.js";

import CloseIcon from '@material-ui/icons/Close';
import { Search } from "@material-ui/icons";


const styles = {
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  newButton: {
    position: "absolute",
    right: "10px",
    marginTop: "-35px",
    color: "#fff",
    borderColor: "white",
    textTransform: "none"
  },
  formControl: {
    margin: 5,
    minWidth: 120,
    maxWidth: 300,
  },
  dialogTitle: {
    background: 'linear-gradient(60deg, #ab47bc, #8e24aa)',
  },

};

const useStyles = makeStyles(styles);
export default function AddMembers({ ...props }) {
   //decllaration des variables de state
  const { openPopupMembers, setOpenPopupMembers, Listmember, confirmeSelect, setSelectedContacts } = props;

  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [pageTitle, setpageTitle] = useState("Liste des contacts");
  // const [SelectedContacts,setSelectedContacts]=useState([])
  const classes = useStyles();
  const [contacts, setContacts] = useState([]);
  const [search, setSearch] = useState("");
  let SelectedContacts = [];
  useEffect(() => {
    let token =serviceToken.GetToken();
    serviceContact.getexepte(Listmember,token.nameid).then(res => {
      setContacts(res.data)
    })
  }, [Listmember]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  //pagination
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, rowsPerPage));
    setPage(0);
  };


  const ReloadContact = () => {
    serviceContact.GetAll().then(respc => {
      setContacts(respc.data);
    });
  }

  const onRemovee = (idgrp, idc) => {

    serviceGroupe.Remove(idgrp, idc).then((res) => {

    })
  };
  const handleChange = (record, event) => {
    if (SelectedContacts.indexOf(record) === -1) {
      SelectedContacts.push(record);
    }
    else {
      SelectedContacts.splice(SelectedContacts.indexOf(record), 1)
    }
  };
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 300,
        width: 250,
      },
    },
  };
  return (

    <Dialog open={openPopupMembers} scroll={'paper'}
      fullWidth={true}
      maxWidth={'md'}  >
      <DialogTitle className={classes.dialogTitle}>
        <div style={{ display: 'flex', width: '100%', height: '100%' }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1, color: 'white' }}>
            {pageTitle}
          </Typography>
          <Button
            variant="contained" color="secondary"
            style={{ textTransform: "none" }}
            onClick={() => { setOpenPopupMembers(false) }}>
            <CloseIcon />
          </Button>
        </div>
      </DialogTitle>

      <DialogContent dividers='true'>
        <GridContainer>

          <GridItem md={8}> </GridItem>
          <GridItem md={4}>
            <TextField
             fullWidth
              margin="normal"
              id="search"
              name="search"
              value={search}
              label="Recherche..."
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </GridItem>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center"></TableCell>
                <TableCell align="center">Nom</TableCell>
                <TableCell align="center">Numéro de téléphone</TableCell>
                <TableCell align="center">E-mail</TableCell>


              </TableRow>
            </TableHead>
            <TableBody>
              {contacts.filter(
                (x) => x.name.includes(search) || x.email.includes(search) || x.phoneNumber.includes(search)
              )
                //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((record, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">
                      <Checkbox onChange={(e) => handleChange(record, e)} color="primary" />
                    </TableCell>
                    <TableCell align="center">{record.name}</TableCell>
                    <TableCell align="center">{record.phoneNumber}</TableCell>
                    <TableCell align="center">{record.email}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {/* <TablePagination
            rowsPerPageOptions={[rowsPerPage]}
            component="div"
            labelRowsPerPage=''
            count={contacts.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
          /> */}
        </GridContainer>
      </DialogContent>
      <DialogActions>
      <Grid container>
        <Grid item xs={4}></Grid>
        <Grid item xs={2}>
          <CustomButton
            fullWidth
            variant="contained"
            classes={{ root: classes.root, label: classes.label }}
            onClick={() => { setOpenPopupMembers(false) }}>
            Annuler</CustomButton>

        </Grid>
        <Grid item xs={2}>
          <CustomButton style={{ marginLeft: '10%' }}
            fullWidth
            onClick={() => { confirmeSelect(SelectedContacts) }}
            variant="contained"
            classes={{ root: classes.root, label: classes.label }}
            color="primary">
            Confirmer</CustomButton>
        </Grid>
</Grid>
      </DialogActions>


    </Dialog>
  );
}
