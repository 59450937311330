/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

// core components
import Admin from "layouts/Admin.js";
import User from "layouts/User.js";

import "assets/css/material-dashboard-react.css?v=1.9.0";
import { ToastProvider } from "react-toast-notifications";
import SignIn from "views/login/login";
import signup from "views/login/signup";
import { ProtectedAdminRoute,ProtectedUserRoute } from "./components/ProtectedRoute";

const hist = createBrowserHistory();

ReactDOM.render(
  <ToastProvider autoDismiss={true} placement="bottom-right">
    
  <Router history={hist}>
    <Switch>
      <ProtectedAdminRoute path="/admin" component={Admin} />
      <ProtectedUserRoute path="/user" component={User} />
      <Route path="/login" component={SignIn} />
      <Route path="/signup" component={signup} />
      <Redirect from="/" to="/login" />
    </Switch>
  </Router>
  </ToastProvider>,
  document.getElementById("root")
);
