import React, { useState,useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import * as serviceToken from "../../Services/serviceToken";
import * as serviceUser from "../../Services/serviceUser";
import { Redirect, useHistory } from "react-router-dom";
import { useToasts } from 'react-toast-notifications';
import ResetPass from './resetPass';
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignIn = ({ ...props }) => {
  const history = useHistory();
  if(serviceToken.isConnected()) history.push("/admin/contact")
  const classes = useStyles();
  const [openPopup, setOpenPopup] = useState(false);
  const { addToast } = useToasts();
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const auth = {
    email: "",
    password: ""
  }
  const openInPopup = () => {
    setOpenPopup(true)
  }
  useEffect(() => 
  {
  }, []);
  const onChangeEmail = (e) => { setEmail(e.target.value) }
  const onChangepass = (e) => { setPass(e.target.value) }
  const handleSubmit = (e) => {
    e.preventDefault();
    connect();
  }
  //naviger vers la page signup
  const signup = () => {
    history.push("/signup")
  }
  //envoi d'un email qui contient le nouveau mot de passe

  const sendemail = (email) => {
    serviceUser.ResetPassword(email).then(response=>{
      if (response.data == "error") {
        addToast("Cette adresse e-mail n'est pas inscrit dans l'application", { appearance: 'error' })
        setOpenPopup(false)
      }
      else {
        addToast("Votre nouveau mot de passe sera envoyé à l'adresse email que vous avez saisie", { appearance: 'info' })
        setOpenPopup(false)
      }
    })
  }
  //verifier les coordonnées passer par l'utilisateur
  //redirigé vers la page d'accueil si valide si non afficher un message d'erreur
  const connect = () => {
    auth.email = email;
    auth.password = pass;
    serviceUser.Connect(auth).then(response=>{
      if (response.data == "error") { addToast("la combinaison de l'adresse e-mail et du mot de passe est invalide", { appearance: 'error' }) }
      else if(response.data == "desactiv"){ addToast("Votre compte a été désactivé", { appearance: 'error' }) }
      else {
        serviceToken.SetToken(response.data.token);
        history.push("/admin/inscription")
      }
    })
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Connexion
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit} autoComplete="off">
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Adresse Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={onChangeEmail}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Mot de Passe"
            type="password"
            id="password"
            autoComplete="new-password"
            value={pass}
            onChange={onChangepass}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Se connecter
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2" onClick={openInPopup}>
                Mot de passe oublié?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2" onClick={signup}>
                S'inscrire
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
      </Box>
        <ResetPass sendemail={sendemail} title="Réinitialiser mon mot de passe"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}></ResetPass>
    </Container>

  );
}
export default SignIn;
