
import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { Button, TextField, Table, TableRow, TableCell, TableHead, TableBody, Tooltip, TablePagination, Dialog, DialogContent, DialogTitle, Typography, InputAdornment, DialogActions, Grid, FormGroup, FormControlLabel, Checkbox } from "@material-ui/core";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import * as serviceGroupe from '../../Services/serviceGroupe';
import * as serviceDevice from '../../Services/serviceDevice';
import * as serviceToken from '../../Services/serviceToken';
import CloseIcon from '@material-ui/icons/Close';
import { Search } from "@material-ui/icons";
import AddIcon from '@material-ui/icons/Add';
import AddGroupeDevice from "./AddGroupeDevice";
import CustomButton from "components/CustomButtons/Button.js";

const styles = {
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    newButton: {
        position: "absolute",
        right: "10px",
        marginTop: "-35px",
        color: "#fff",
        borderColor: "white",
        textTransform: "none"
    },
    formControl: {
        margin: 5,
        minWidth: 120,
        maxWidth: 300,
    },
    dialogTitle: {
        background: 'linear-gradient(60deg, #ab47bc, #8e24aa)',
    },
};
const useStyles = makeStyles(styles);
export default function AddMembers({ ...props }) {
     //decllaration des variables de state
    const { openPopupGroupe, setopenPopupGroupe, deviceForEdit, setdeviceForEdit,addOrEdit } = props;
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);
    const [pageTitle, setpageTitle] = useState("Configuration des notifications");
    const classes = useStyles();
    const [search, setSearch] = useState("");
    const [openPopupAddGroupeDevice, setopenPopupAddGroupeDevice] = useState(false);
    const [sendSMS, setsendSMS] = useState(false);
    const [Phonecall, setPhonecall] = useState(false);
    const [Listgroupe, setListgroupe] = useState([]);
    const [ListNewgroupe, setListNewgroupe] = useState([]);
    
    let SelectedGroups = [];
    useEffect(() => {
        Reload();

    }, [deviceForEdit]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    //pagination
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, rowsPerPage));
        setPage(0);
    };
    const onRemovee = (iddv, idc) => {
        serviceDevice.Remove(iddv, idc).then((res) => {
            ReloadList();
        })
    };
    const Reload = () => {
        
        if (deviceForEdit != null) {
            setsendSMS(deviceForEdit.sendSMS);
            setPhonecall(deviceForEdit.phoneCall);
           
            serviceDevice.GetGroupeByDeviceId(deviceForEdit._id).then(resp => {
                setListgroupe(resp.data);

            });
        }
    };
    const ReloadList = () => {
        
        if (deviceForEdit != null) {
            serviceDevice.GetGroupeByDeviceId(deviceForEdit._id).then(resp => {
                setListgroupe(resp.data);
            });}
}
    const handleChangeSMS = () => {
        setsendSMS(!sendSMS);
    };
    const handleChangePhone = () => {
        setPhonecall(!Phonecall)
       

    };
  
const onconfirme=()=>{
    serviceDevice.GetById(deviceForEdit._id).then(res=>{
        setdeviceForEdit(res.data)
        res.data.sendSMS=sendSMS;
        res.data.phoneCall=Phonecall;
    addOrEdit(res.data);
    setopenPopupGroupe(false)
    })
    
}
    const confirmeSelect = (Listgroupes => {
        let obj = {
            groupe: ListNewgroupe,
            id_device: deviceForEdit._id
        }

        serviceDevice.Addmember(obj).then(rep => {
            setopenPopupAddGroupeDevice(false);
            ReloadList();
        });



    });
    const handleChange = (record, event) => {

    };
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 300,
                width: 250,
            },
        },
    };
    return (
        <Dialog open={openPopupGroupe} scroll={'paper'}
            fullWidth={true}
            maxWidth={'md'}  >
            <DialogTitle className={classes.dialogTitle}>
                <div style={{ display: 'flex', width: '100%', height: '100%' }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1, color: 'white' }}>
                        {pageTitle}
                    </Typography>
                    <Button
                        variant="contained" color="secondary"
                        onClick={() => { setopenPopupGroupe(false) }}>
                        <CloseIcon />
                    </Button>
                </div>
            </DialogTitle>
            <DialogContent dividers='true'>
                <GridContainer>
                    <GridItem md={8}>
                        <FormGroup row>
                            <FormControlLabel  disabled control={<Checkbox checked name="Mail" />} label="E-mail" />

                            <FormControlLabel style={{color:"black"}}
                                control={<Checkbox checked={sendSMS} onChange={handleChangeSMS} name="SMS" color="primary" />}
                                label="Envoie par SMS"
                            />
                            {/* <FormControlLabel style={{color:"black"}}
                                control={<Checkbox checked={Phonecall} onChange={handleChangePhone} name="Phone" color="primary" />}
                                label="Appel téléphonique"
                            /> */}
                        </FormGroup>
                    </GridItem>
                    <GridItem md={4}>

                        <Button
                            variant="outlined"
                            startIcon={<AddIcon />}
                            color="primary"
                            style={{ float: 'right',  textTransform: "none" }}
                            onClick={() => {
                                setopenPopupAddGroupeDevice(true);
                                // setRecordForEdit(null);
                            }}
                        >
                            Ajouter des groupes
                </Button>
                    </GridItem>
                    </GridContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Nom</TableCell>
                                <TableCell align="center">Nombre des contacts</TableCell>
                                <TableCell align="center">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Listgroupe.filter(
                                (x) => x.name.includes(search))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((record, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="center">{record.name}</TableCell>
                                        <TableCell align="center">{record.contacts.length}</TableCell>
                                        
                                        <TableCell align="center">
                                            <Tooltip title="Retirer groupe">
                                                <Button
                                                    onClick={
                                                        () => {
                                                            onRemovee(deviceForEdit._id, record._id)
                                                        }
                                                    }
                                                >
                                                    <HighlightOffIcon color="secondary" />
                                                </Button></Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[rowsPerPage]}
                        component="div"
                        labelRowsPerPage=''
                        count={Listgroupe.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                    />
               
            </DialogContent>
            <DialogActions>
                <Grid container>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={2}>
                        <CustomButton
                            fullWidth
                            variant="contained"
                            classes={{ root: classes.root, label: classes.label }}
                            onClick={() => { setdeviceForEdit(null); setopenPopupGroupe(false) }}
                        >Annuler</CustomButton>

                    </Grid>

                    <Grid item xs={2}>
                        <CustomButton style={{ marginLeft: '10%' }}
                            fullWidth
                            onClick={() => { onconfirme() }}
                            variant="contained"
                            classes={{ root: classes.root, label: classes.label }}
                            color="primary">
                            Confirmer</CustomButton>
                    </Grid>
                </Grid>
            </DialogActions>
            <AddGroupeDevice
                //    deviceForEdit={deviceForEdit}
                //    setdeviceForEdit={setdeviceForEdit}
                confirmeSelect={confirmeSelect}
                Listgroupe={Listgroupe}
                openPopupAddGroupeDevice={openPopupAddGroupeDevice}
                setopenPopupAddGroupeDevice={setopenPopupAddGroupeDevice}
                setListNewgroupe={setListNewgroupe}
            ></AddGroupeDevice>
        </Dialog>
    );
}
