
import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import { Button, Dialog, DialogContent, DialogTitle, Typography,  DialogActions } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
const styles = {
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    newButton: {
        position: "absolute",
        right: "10px",
        marginTop: "-35px",
        color: "#fff",
        borderColor: "white",
        textTransform: "none"
    },
    formControl: {
        margin: 5,
        minWidth: 120,
        maxWidth: 300,
    },
    dialogTitle: {
        background: 'linear-gradient(60deg, #ab47bc, #8e24aa)',
    },
};
const useStyles = makeStyles(styles);
export default function Detailerreur({ ...props }) {
     //decllaration des variables de state
    const { MsgErreur,openPopupErreur,setopenPopupErreur} = props;
    const [pageTitle, setpageTitle] = useState("Message d'erreur");
    const classes = useStyles();
    
    useEffect(() => {
       

    }, []);
    return (
        <Dialog open={openPopupErreur} scroll={'paper'}
            fullWidth={true}
            maxWidth={'md'}  >
            <DialogTitle className={classes.dialogTitle}>
                <div style={{ display: 'flex', width: '100%', height: '100%' }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1, color: 'white' }}>
                        {pageTitle}
                    </Typography>
                    <Button
                        variant="contained" color="secondary"
                        onClick={() => { setopenPopupErreur(false) }}>
                        <CloseIcon />
                    </Button>
                </div>
            </DialogTitle>
            <DialogContent dividers='true'>
                {MsgErreur}
            </DialogContent>
           
        </Dialog>
    );
}
