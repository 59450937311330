import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import * as serviceTestcase from '../../Services/serviceTestcase';
import CustomButton from "components/CustomButtons/Button.js";
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0.5),
  },
  label: {
    textTransform: "none",
  },
  form: {
    width: "100%", // Fix IE 11 issue.

    // marginTop: theme.spacing(1),
  },
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
    height: "auto",
    maxHeight: "80%",
  },
  dialogTitle: {
    background: 'linear-gradient(60deg, #ab47bc, #8e24aa)',
  },
  selectInput: {
    //margin: theme.spacing(1),
    minWidth: 120,
    width: "100%",
    marginTop: 8,
  },
  textInput: {
    marginTop: 0,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function TestCaseForm({ ...props }) {
  const classes = useStyles();
  //déclaration des variables de state
  const {
    AoEtestcase, //submit
    chosenDevice, //selected record for edit
    setChosenDevice,
    openPopupTC, //varible affectd to the popup
    setOpenPopupTC, //close popup
  } = props;
  const [pageTitle, setpageTitle] = useState("Ajouter un cas de test");

  const [keyWord, setKeyWord] = useState("");
  const [testCase, setTestCase] = useState(null);
  
  //useEffect se déclenche au moment du chargement de composent
  //récupérer le sms urgent a modifier si il exite
  useEffect(() => {
    if(chosenDevice){
      serviceTestcase.GetByDeviceId(chosenDevice._id).then(res => {
        if(res.data.length>0)
        {
          setTestCase(res.data[0])
          setpageTitle("Modifier le cas de test")
          setKeyWord(res.data[0].keyWord)
        }
        else{
          setTestCase(null)
          setKeyWord("")
        }
      })
    }
    
  }, [chosenDevice]);


  const onChangekeyWord = (e) => {
    setKeyWord(e.target.value);
    // if (e.target.value.trim().length > 0) setKeyWordErr(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // const isValid = formValidation();
    // if (isValid) {
      if(testCase!=null){
        testCase.keyWord=keyWord
        AoEtestcase(testCase);

      }else{
        let tc={
          keyWord:keyWord,
          device_id:chosenDevice._id
        }
        AoEtestcase(tc);
        setKeyWord("")
      }
      
      // } else {}
    
  };
  //form validation
  // const formValidation = () => {
  //   let isValid = true;
  //   if (keyWord.trim().length < 1) {
  //     isValid = false;
  //     setKeyWordErr(true);
  //   }
  //   return isValid;
  // };
  
  return (
    <>
      <Dialog
        open={openPopupTC}
        scroll={'paper'}
        maxWidth="md"
        >
        <DialogTitle className={classes.dialogTitle}>
          <div style={{ display: "flex", width: '100%', height: '100%' }}>
            <Typography variant="h6" component="div" style={{ flexGrow: 1, color: 'white' }}>
              {pageTitle}
            </Typography>
            <Button
              variant="contained" color="secondary"
              onClick={() => {
                setOpenPopupTC(false);
              }}
            >
              <CloseIcon />
            </Button>
          </div>
        </DialogTitle>
        <form onSubmit={handleSubmit} className={classes.form}>
          <DialogContent dividers='true'>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                style={{width:'500px'}}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="keyWord"
                  label="Chaîne de test"
                  name="keyWord"
                  value={keyWord}
                  onChange={onChangekeyWord}
                  // error={keyWordErr}
                  // helperText={
                  //   keyWordErr ? "La Chaîne de test doit être renseigné" : ""
                  // }
                  className={classes.textInput}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container>
              <Grid item xs={4}></Grid>
              <Grid item xs={2}>
                <CustomButton
                  fullWidth
                  variant="contained"
                  classes={{ root: classes.root, label: classes.label }}
                  onClick={() => { setChosenDevice(null); setOpenPopupTC(false) }}
                  color="primary"
                >Annuler</CustomButton>

              </Grid>

              <Grid item xs={2}>
                <CustomButton style={{ marginLeft: '10%' }}
                  fullWidth
                  type="submit"
                  variant="contained"
                  classes={{ root: classes.root, label: classes.label }}
                  color="primary">
                  Confirmer</CustomButton>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
