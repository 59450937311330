import http from "./env";

export const Connect = (user) => {
    return http.post("/User/login",user);
  };
  export const Register = (user) => {
    return http.post("/User/Register",user);
  };
  export const ResetPassword = (email) => {
    return http.get("/User/ResetPassword/"+email);
  };