import http from "./env";
import AuthHeader from "./env";
import * as serviceToken from"./serviceToken";
export const GetAll = () => {
    return http.get("/Contact/Get", {headers:{ Authorization: `Bearer ${serviceToken.gettokenstring()}` }}
    );
  };
  export const Update = (contact) => {
    return http.post("/Contact/Update",contact, {headers:{ Authorization: `Bearer ${serviceToken.gettokenstring()}` }})    ;
  };
  export const Insert = (contact) => {
    return http.post("/Contact/Insert",contact, {headers:{ Authorization: `Bearer ${serviceToken.gettokenstring()}` }}    );
  };
  export const Delete = (id) => {
    return http.delete("/Contact/Delete/"+id, {headers:{ Authorization: `Bearer ${serviceToken.gettokenstring()}` }}    );
  };
export const getexepte=(currentList,userId)=>
{
  return http.post("/Contact/GetExcept/"+userId,currentList, {headers:{ Authorization: `Bearer ${serviceToken.gettokenstring()}` }}  );
};
export const GetContactUserbyid=(id)=>
{
return http.get("/Contact/GetByUserId/"+id,{headers:{ Authorization: `Bearer ${serviceToken.gettokenstring()}` }});

}