import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Box, Tab, Tabs, Tooltip, Typography } from "@material-ui/core";
import PropTypes from 'prop-types';
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Card from "components/Card/Card.js";
import AverageTimeStat from "./AverageTimeStat";
import DownCountStat from "./DownCountStat";
import LastUpDurationStat from "./LastUpDurationStat";
import MonthlyDownTime from "./MonthlyDownTime";
import UniqueIssuesStat from "./UniqueIssuesStat";

const styles = {
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
          color: "#777",
          fontSize: "65%",
          fontWeight: "400",
          lineHeight: "1"
        }
      },
}

const useStyles = makeStyles(styles);
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }
export default function Statistic({ ...props } ) {
    const [value, setValue] = React.useState(0);
  const classes = useStyles();
  useEffect(() => {
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  return (
    <GridContainer>
    <GridItem xs={12} sm={12} md={12}>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Statistiques (Mise à jour à minuit)</h4>
        </CardHeader>
        <CardBody>
    <Tabs
    // orientation="vertical"
    variant="scrollable"
    value={value}
    onChange={handleChange}
    aria-label="Vertical tabs example"
    className={classes.tabs}
    // style={{ background: 'linear-gradient(60deg,#cc4de3, #da7aeb)',color:'white'}}
  >
    <Tooltip title="Temps de réponse moyen">
    <Tab label="Temps de réponse moyen" {...a11yProps(0)} style={{backgroundColor:"#c5c9e3",marginRight:"1%"}}/>
    </Tooltip>
    <Tooltip title="Nombre d'échecs total">
    <Tab label="Nombre d'échecs" {...a11yProps(1)} style={{backgroundColor:"#c5c9e3",marginRight:"1%"}}/>
    </Tooltip>
    <Tooltip title="Durée de bon fonctionnement depuis le dernier échec">
    <Tab label="Durée de bon fonctionnement" {...a11yProps(2)}style={{backgroundColor:"#c5c9e3",marginRight:"1%"}} />
    </Tooltip>
    <Tooltip title="Nombre de type d'échec different">
    <Tab label="NTED" {...a11yProps(3)}style={{backgroundColor:"#c5c9e3",marginRight:"1%"}} />
    </Tooltip>
    <Tooltip title="Temps total d'échec">
    <Tab label="TTE" {...a11yProps(4)}style={{backgroundColor:"#c5c9e3",marginRight:"1%"}} />
    </Tooltip>
  </Tabs>
  <TabPanel value={value} index={0}>
  <AverageTimeStat></AverageTimeStat>
</TabPanel>
<TabPanel value={value} index={1}>
  <DownCountStat></DownCountStat>
</TabPanel>
<TabPanel value={value} index={2}>
  <LastUpDurationStat></LastUpDurationStat>
</TabPanel>
<TabPanel value={value} index={3}>
  <UniqueIssuesStat></UniqueIssuesStat>
</TabPanel>
<TabPanel value={value} index={4}>
  <MonthlyDownTime></MonthlyDownTime>
</TabPanel>
</CardBody>
</Card>
</GridItem>
</GridContainer>
  );
}
