import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import AddGroupe from "./AddGroupe";
import HistoriqueDevice from "./HistoriqueDevice";
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TableContainer from "@material-ui/core/TableContainer";
import CardBody from "components/Card/CardBody.js";
import {
  Button,
  TextField,
  Table,
  InputAdornment,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  ButtonGroup,
  Tooltip,
  TablePagination,
  TableSortLabel,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Search from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useToasts } from "react-toast-notifications";
import * as serviceDevice from "../../Services/serviceDevice";
import * as servicePlanning from "../../Services/servicePlanning";
import DeviceForm from "./DeviceForm";
import ConfirmDialog from "components/ConfirmDialog";
import * as serviceToken from '../../Services/serviceToken';
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import HelpIcon from "@material-ui/icons/Help";
import { green } from "@material-ui/core/colors";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import * as serviceDevicetype from '../../Services/serviceDevicetype';
import HistoryIcon from '@material-ui/icons/History';
import * as moment from 'moment';
import orderBy from 'lodash/orderBy'
import Paper from "@material-ui/core/Paper";
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import TestCaseForm from "./TestCaseForm";
import * as serviceTestcase from '../../Services/serviceTestcase';
import ListAltIcon from '@material-ui/icons/ListAlt';
import CircularProgress from '@material-ui/core/CircularProgress';

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset"
    }
  }
});
const styles = {
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  newButton: {
    position: "absolute",
    right: "10px",
    marginTop: "-35px",
    color: "#fff",
    borderColor: "white",
    textTransform: "none",
  },
  sheetButton: {
    position: "absolute",
    right: "200px",
    marginTop: "-35px",
    color: "#fff",
    borderColor: "white",
    textTransform: "none",
  },
  searchBar: {},
};
const useStyles = makeStyles(styles);
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Libellé', sortable: true },
  { id: 'codeAffaire', numeric: false, disablePadding: false, label: 'Code affaire', sortable: true },
  { id: 'frequency', numeric: true, disablePadding: false, label: 'Fréquence', sortable: true },
  { id: 'hd', numeric: false, disablePadding: false, label: 'Heure début', sortable: false },
  { id: 'hf', numeric: false, disablePadding: false, label: 'Heure fin', sortable: false },
  { id: 'stat', numeric: false, disablePadding: false, label: 'Status', sortable: false },
  { id: 'act', numeric: false, disablePadding: false, label: 'Actions', sortable: false },
];
export default function Device({ ...props }) {
  //decllaration des variables de state
  const [devices, setDevices] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [deviceForEdit, setdeviceForEdit] = useState(null);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [Listgroupe, setListgroupe] = useState([]);
  const [page, setPage] = React.useState(0);
  const [search, setSearch] = useState("");
  const [devicetypes, setdevicetypes] = useState([]);
  const [openPopupGroupe, setopenPopupGroupe] = useState(false);
  const [openPopuphistory, setOpenPopuphistory] = useState(false);
  const [deviceEdit, setdeviceEdit] = useState(null);
  const [chosenDevice,setChosenDevice]= useState(null);
  const [openPopupTC, setOpenPopupTC] = useState(false);
  const [SheetsLoading, setSheetsLoading] = useState(false);

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('client');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const classes = useStyles();
  useEffect(() => {

    serviceDevicetype.GetAll().then(res => {
      setdevicetypes(res.data);
    })
    Reload();
    // 'This will run every 60 second!'
    const interval = setInterval(() => {
      Reload();
    }, 60000);
    return () => clearInterval(interval);

  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  //pagination
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, rowsPerPage));
    setPage(0);
  };
  const { addToast } = useToasts();
  //faire appel a l'API ajouter ou modifier message et afficher et afficher une notification
  const addOrEdit = (record, plan) => {
    if (record._id == null) {
      let devicePlanning = {
        planning: plan,
        device: record
      }
      serviceDevice.InsertDevicePlanning(devicePlanning).then((resp) => {
        addToast("Dispositif ajouté avec succès", { appearance: "info" });
        Reload();
      });
    } else
      serviceDevice.Update(record, plan).then((resp) => {
        servicePlanning.Update(plan)
        addToast("Dispositif modifié avec succès", { appearance: "info" });
        Reload();
      });
    setRecordForEdit(null);
    setOpenPopup(false);
  };
  const AoEtestcase=(testcase)=>{
    if(testcase._id==null){
      serviceTestcase.Insert(testcase).then((resp) => {
        addToast("Cas de test ajouté avec succès", { appearance: "info" });
        Reload();
      });
    }else{
      serviceTestcase.Update(testcase).then((resp) => {
        addToast("Cas de test modifié avec succès", { appearance: "info" });
        Reload();
      });
    }
    setChosenDevice(null);
    setOpenPopupTC(false);
  }
  const openInPopup = (record) => {
    setRecordForEdit(record);
    setOpenPopup(true);
  };
  //supprimer un contact et afficher un notification
  const onDelete = (id) => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    serviceDevice.Delete(id).then((res) => {

      addToast("Dispositif supprimé", { appearance: "info" });
      Reload();
    });
  };
  const Reload = () => {
    let token = serviceToken.GetToken();


    serviceDevice.GetDeviceByClient(token.nameid).then(resp => {
      setDevices(resp.data);
    });

  };
  const startTracking = (record) => {
    record.track = true;
    serviceDevice.Update(record).then((resp) => {
      /*addToast("Device modifié avec succès", { appearance: "info" });*/
      Reload();
    });
  };
  const stopTracking = (record) => {

    record.track = false;
    record.active = false;
    serviceDevice.Update(record).then((resp) => {
      /*addToast("Device modifié avec succès", { appearance: "info" });*/
      Reload();
    });
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const UpdateSheet = () => {
    setSheetsLoading(true)
  serviceDevice.UpdateGoogleSheet().then((resp) => {
    if (resp.data == "ok") addToast("Le fichier a été mis à jour", { appearance: "info" });
    else addToast("Une erreur s'est produite", { appearance: "info" });
    setSheetsLoading(false)
  });
  };
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Liste des dispositifs</h4>
            {SheetsLoading?
              <CircularProgress className={classes.sheetButton}/>
            :
            <Button
              variant="outlined"
              startIcon={<ListAltIcon />}
              className={classes.sheetButton}
              onClick={() => {
                UpdateSheet()
              }}
            >Mettre à jour le spreadsheet
            </Button>}
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              className={classes.newButton}
              onClick={() => {
                setOpenPopup(true);
                setRecordForEdit(null);
              }}
            >
              Nouveau dispositif
            </Button>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem md={9}>
                {/* <div>
                <input type="file" onChange={this.onFileChange} />
                <button onClick={this.onFileUpload}>
                  Upload!
                </button>
            </div>  */}
            </GridItem>
              <GridItem md={3}>
                <TextField
                  fullWidth
                  margin="normal"
                  id="search"
                  name="search"
                  value={search}
                  label="Recherche..."
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </GridItem>
            </GridContainer>




            <TableContainer component={Paper}>

              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                  <TableCell style={{ width: "10px" }} />
                    <TableCell
                      align="left"
                      style={{ fontSize: "30px" }}
                      sortDirection={order}

                    >
                      <TableSortLabel
                        active={true}
                        direction={order }
                        onClick={createSortHandler('client')}
                      >
                        <b style={{color:"#ab47bc"}}>Client</b>
                      </TableSortLabel>
                    </TableCell>
                   
                  </TableRow>
                </TableHead>
                <TableBody>


                  {stableSort(devices, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((record) => (
                      <Row key={record.client} row={record}
                        setopenPopupGroupe={setopenPopupGroupe}
                        setdeviceForEdit={setdeviceForEdit}
                        stopTracking={stopTracking}
                        startTracking={startTracking}
                        openInPopup={openInPopup}
                        setConfirmDialog={setConfirmDialog}
                        onDelete={onDelete}
                        setOpenPopuphistory={setOpenPopuphistory}
                        setdeviceEdit={setdeviceEdit}
                        search={search}
                        chosenDevice={chosenDevice}
                        setChosenDevice={setChosenDevice}
                        openPopupTC={openPopupTC}
                        setOpenPopupTC={setOpenPopupTC}
                      />
                    ))}

                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                component="div"
                labelRowsPerPage=""
                count={devices.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) =>
                  `Page:${page+1}  (${from}-${to} de ${count})`
                }
              />
            </TableContainer>
          </CardBody>
        </Card>
      </GridItem>
      <DeviceForm
        recordForEdit={recordForEdit}
        setRecordForEdit={setRecordForEdit}
        addOrEdit={addOrEdit}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      ></DeviceForm>
      <HistoriqueDevice
        deviceEdit={deviceEdit}
        setdeviceEdit={setdeviceEdit}
        openPopuphistory={openPopuphistory}
        setOpenPopuphistory={setOpenPopuphistory}
      ></HistoriqueDevice>
      <AddGroupe
        addOrEdit={addOrEdit}
        deviceForEdit={deviceForEdit}
        setdeviceForEdit={setdeviceForEdit}
        openPopupGroupe={openPopupGroupe}
        setopenPopupGroupe={setopenPopupGroupe}
      ></AddGroupe>
      <TestCaseForm
      openPopupTC={openPopupTC}
      setOpenPopupTC={setOpenPopupTC}
      chosenDevice={chosenDevice}
      setChosenDevice={setChosenDevice}
      AoEtestcase={AoEtestcase}
      >
      </TestCaseForm>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </GridContainer>
  );
}
function Row(props) {
  const { row, setopenPopupGroupe, setdeviceForEdit, stopTracking, startTracking,
   openInPopup, setConfirmDialog, onDelete, setOpenPopuphistory, setdeviceEdit, search,
   chosenDevice,setChosenDevice,openPopupTC,setOpenPopupTC } = props;
  const [open, setOpen] = React.useState(true);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const classes = useRowStyles();
  return (
    <React.Fragment>

      {row.listDevice.filter(device => device.name.toLowerCase().includes(search.toLowerCase())|| device.codeAffaire.toLowerCase().includes(search.toLowerCase())).length > 0 ?
        <>
          <TableRow className={classes.root}>
            <TableCell style={{ width: "10px" }}>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell component="th" align="left" style={{ fontSize: "30px" }} onClick={() => setOpen(!open)}>
              {row.client}
            </TableCell>

          </TableRow>

          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box margin={1}>

                  <Table size="small" aria-label="purchases">
                    <EnhancedTableHead
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {stableSort(row.listDevice, getComparator(order, orderBy))
                        // .filter(device => device.name?.includes(search) || device.codeAffaire?.includes(search))
                        .map((device) => (
                          <TableRow key={device._id}>
                            <TableCell component="th" scope="row">
                              {device.name}
                            </TableCell>
                            <TableCell align="center">{device.codeAffaire}</TableCell>
                            <TableCell align="center">{device.frequency}</TableCell>
                            <TableCell align="center">{moment(device.planning.startDate).format("HH:mm")}</TableCell>
                            <TableCell align="center">{moment(device.planning.stopDate).format("HH:mm")}</TableCell>
                            <TableCell align="center">
                              {device.track ? (
                                device.status ? (
                                  <Tooltip title="active">
                                    <CloudUploadIcon
                                      style={{ color: green[500] }}
                                    ></CloudUploadIcon>
                                  </Tooltip>
                                ) : (
                                    <Tooltip title="hors service">
                                      <CloudDownloadIcon color="secondary"></CloudDownloadIcon>
                                    </Tooltip>
                                  )
                              ) : (
                                  <Tooltip title="inconnu">
                                    <HelpIcon></HelpIcon>
                                  </Tooltip>
                                )}
                            </TableCell>
                            <TableCell align="center">
                              <ButtonGroup variant="text">
                                <Tooltip title="Affecter groupe">
                                  <Button
                                    onClick={() => {
                                      setopenPopupGroupe(true);
                                      setdeviceForEdit(device);
                                    }}
                                  >
                                    <GroupAddIcon color="primary" />
                                  </Button>
                                </Tooltip>
                                {device.track ? (
                                  <Tooltip title="pas de surveillance">
                                    <Button
                                      onClick={() => {
                                        stopTracking(device);
                                      }}
                                    >
                                      <VisibilityIcon color="primary" />
                                    </Button>
                                  </Tooltip>
                                ) : (
                                    <Tooltip title="surveillance">
                                      <Button
                                        onClick={() => {
                                          startTracking(device);
                                        }}
                                      >
                                        <VisibilityOffIcon color="primary" />
                                      </Button>
                                    </Tooltip>
                                  )}
                                <Tooltip title="Modifier">
                                  <Button
                                    color="primary"
                                    onClick={() => {
                                      openInPopup(device);
                                    }}
                                  >
                                    <EditIcon color="primary" />
                                  </Button>
                                </Tooltip>
                                <Tooltip title="Supprimer">
                                  <Button
                                    onClick={() => {
                                      setConfirmDialog({
                                        isOpen: true,
                                        title: "Confirmer la suppression",
                                        subTitle:
                                          "Voulez-vous vraiment supprimer ce dispositif ?",
                                        onConfirm: () => {
                                          onDelete(device._id);
                                        },
                                      });
                                    }}
                                  >
                                    <DeleteIcon color="secondary" />
                                  </Button>
                                </Tooltip>
                                <Tooltip title="Historique">
                                  <Button
                                    color="primary"
                                    onClick={() => {
                                      setOpenPopuphistory(true);
                                      setdeviceEdit(device);
                                    }}
                                  >
                                    <HistoryIcon color="primary" />
                                  </Button>
                                </Tooltip>
                                <Tooltip title="Cas de test">
                                  <Button
                                    color="primary"
                                    onClick={() => {
                                      setOpenPopupTC(true);
                                      setChosenDevice(device)
                                    }}
                                  >
                                    <NoteAddIcon color="primary" />
                                  </Button>
                                </Tooltip>
                              </ButtonGroup>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
        : <div></div>
      }
    </React.Fragment>
  );
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable ?
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
              :
              headCell.label
            }

          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}