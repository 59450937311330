
import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Button, TextField, Table, InputAdornment, TableRow, TableCell, TableHead, TableBody, ButtonGroup, Tooltip, TablePagination } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import Search from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { useToasts } from "react-toast-notifications";
import * as serviceGroupe from '../../Services/serviceGroupe';
import GroupesForm from './GroupesForm'
import * as serviceToken from '../../Services/serviceToken';
import ConfirmDialog from "components/ConfirmDialog";
const styles = {
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  newButton: {
    position: "absolute",
    right: "10px",
    marginTop: "-35px",
    color: "#fff",
    borderColor: "white",
    textTransform: "none"
  },
  searchBar: {

  }
};

const useStyles = makeStyles(styles);
export default function Groupes({ ...props }) {
   //decllaration des variables de state
  const [groupes, setGroupe] = useState([]);
  const [Listcontact, setListcontact] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupMembers, setOpenPopupMembers] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [idgroupe, setIdgroupe] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [search, setSearch] = useState("");
  const [Listmember, setListmember] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const classes = useStyles();
  useEffect(() => {
    Reload();}, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  //pagination
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, rowsPerPage));
    setPage(0);
  };
  const { addToast } = useToasts();
  //faire appel a l'API ajouter ou modifier message et afficher et afficher une notification
  const addOrEdit = (record, listcontact) => {
    if (record._id == null) {
      serviceGroupe.Insert(record).then((resp) => {

        addToast("Groupe ajouté avec succès", { appearance: "info" });
        Reload();
        getListemember(record._id);
      })
    } else {
      record.date_deleted = null
      serviceGroupe.Update(record).then((resp) => {

        addToast("Groupe modifié avec succès", { appearance: "info" });
        Reload();
        getListemember(record._id);
      })
    }
    setRecordForEdit(null);
    setOpenPopup(false);
  };

  const openInPopup = (record) => {
    setRecordForEdit(record);
    setIdgroupe(record._id)
    setOpenPopup(true);
  };

  const openInPopupMember = (record) => {
    setIdgroupe(record._id)
    setOpenPopupMembers(true);
  };
  //supprimer un groupe et afficher un notification
  const onDelete = (id) => {
    setConfirmDialog({ ...confirmDialog, isOpen: false })
    serviceGroupe.Delete(id).then((res) => {
      if(res.data==801){
        addToast("Ce groupe est affecté à un dispositif", { appearance: "warning" , position: "Top-right !important" });
      Reload();
      }
      else{
      addToast("Groupe supprimé", { appearance: "info" });
      Reload();
    }
    })
  };
  const Reload = () => {
    let token = serviceToken.GetToken();
      serviceGroupe.GetGroupeUserbyid(token.nameid).then(resp => {
        setGroupe(resp.data);
      });
    
  }
  const getListemember = (idgroupe) => {
    serviceGroupe.Getmembr(idgroupe).then(resp => {
      setListmember(resp.data)
    });}

  return (
    <>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Liste des groupes</h4>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            className={classes.newButton}
            onClick={() => {
              setOpenPopup(true);
              setRecordForEdit(null);
              setListmember([])
            }}
          >
            Nouveau groupe
                </Button>

        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem md={9}> </GridItem>
            <GridItem md={3}>
              <TextField
              fullWidth
                margin="normal"
                id="search"
                name="search"
                value={search}
                label="Recherche..."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </GridItem>
          </GridContainer>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Libellé</TableCell>
                <TableCell align="center">Nombre des contacts</TableCell>

                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groupes.filter(
                (x) => x.name.toLowerCase().includes(search.toLowerCase())
              )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((record, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">{record.name}</TableCell>
                  
                    <TableCell align="center">{record.contacts.length}</TableCell>
                    
                    <TableCell align="center">
                      <ButtonGroup variant="text">


                        <Tooltip title="Modifier">
                          <Button
                            color="primary"
                            onClick={() => {
                              openInPopup(record)
                            }}
                          >
                            <EditIcon color="primary" />
                          </Button></Tooltip>
                        <Tooltip title="Supprimer">
                          <Button
                            onClick={
                              () => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title: "Confirmer la suppression",
                                  subTitle: "Voulez-vous vraiment supprimer ce groupe ?",
                                  onConfirm: () => { onDelete(record._id) }
                                });
                              }
                            }
                          >
                            <DeleteIcon color="secondary" />
                          </Button></Tooltip>


                      </ButtonGroup></TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[rowsPerPage]}
            component="div"
            labelRowsPerPage=''
            count={groupes.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => `Page:${page+1}  (${from}-${to} de ${count})`}
          />
        </CardBody>
      </Card>

      {/* popup pour ajouter un nouveau groupe */}
      <GroupesForm
        ReloadGroups={Reload}
        Listmember={Listmember}
        setListmember={setListmember}
        Listcontact={setListcontact}
        idgroupe={idgroupe}
        setIdgroupe={setIdgroupe}
        recordForEdit={recordForEdit}
        setRecordForEdit={setRecordForEdit}
        addOrEdit={addOrEdit}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      ></GroupesForm>


      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
