import http from "./env";
import AuthHeader from "./env";
import * as serviceToken from"./serviceToken";

export const GetDeviceAverageLatency = (id) => {
    return http.get("/Statistic/GetDeviceAverageLatency/"+id, {headers:{ Authorization: `Bearer ${serviceToken.gettokenstring()}` }}    );
  };
export const GetDeviceDownCounter = (id) => {
    return http.get("/Statistic/GetDeviceDownCounter/"+id, {headers:{ Authorization: `Bearer ${serviceToken.gettokenstring()}` }}    );
  };
  export const RecentUptime = (id) => {
    return http.get("/Statistic/RecentUptime/"+id, {headers:{ Authorization: `Bearer ${serviceToken.gettokenstring()}` }}    );
  };
  export const UniqueIssuesLastMonth = (id) => {
    return http.get("/Statistic/UniqueIssuesLastMonth/"+id, {headers:{ Authorization: `Bearer ${serviceToken.gettokenstring()}` }}    );
  };
  export const TotalDowntimeLastMonth = (id) => {
    return http.get("/Statistic/TotalDowntimeLastMonth/"+id, {headers:{ Authorization: `Bearer ${serviceToken.gettokenstring()}` }}    );
  };